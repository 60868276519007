import React, { useState, useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Spin } from "antd";
// REDUX
import { useSelector } from "react-redux";
import { usePostGeneralReportMutation } from "../../../redux/reports/reportsAPI";
// COMPONENTS
import ReportsTable from "../ReportsTable";
import ReportsHeader from "../ReportsHeader";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";

const ReportFaultGeneral = () => {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [loadPage, setLoadPage] = useState(false);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);
	const { theme } = useSelector((state: any) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetReport, { data, isLoading, isError, error }] =
		usePostGeneralReportMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const generateTableRequestBody = ({ downloadReport }: any) => {
		const body = { download: downloadReport };
		return body;
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		setLoadPage(true);
		setTimeout(() => {
			setLoadPage(false);
		}, 1000);
	}, []);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Spin spinning={loadPage}>
			{validatePermission("fault-report", permissions) ? (
				<div className={`layout__contentPage__${theme}`}>
					<ReportsHeader
						reportDefaultData={{
							reportType: "REPORT",
							levelTypeName: "ORGANIZATIONS",
							displaySelectTypeLevel: true,
							liftType: "ALL",
							defaultColumns: [
								"created_at",
								"org_name",
								"server_name",
								"bank_name",
								"lift_name",
								"type",
								"short_msg",
								"message",
							],
							defaultOffsets: ["FAULT"],
							infoDescriptionText: "report.infoDescriptionText1",
							displayDownloadCSVReport: false,
							displayDownloadPDFReport: true,
							reportTitle: "",
							displayAdvanceFiltersExclusions: false,
							displayAdvanceFiltersOffsets: true,
							disabledOffsets: true,
							useTablePagination: true,
							displayDateByRange: true,
							displayDate: true,
							displaySelectLiftType: true,
							displayAdvanceFilterCharts: false,
							displayAdvanceFiltersTable: true,
							displayRecentWeeksPicket: false,
							displayInputLimitItems: false,
							displayChartMirror: false,
							chartMirror: false,
							chartType: "bar_stack_vertical",
							dateTypeOptions: [
								{ id: "TODAY", name: "Day" },
								{ id: "WEEK", name: "Week" },
								{ id: "MONTH", name: "Month" },
								{ id: "QUARTER", name: "Quarter" },
								{ id: "YEAR", name: "Year" },
							],
							dateDaysLimit: 0,
							displayMinimumActivation: false,
							dateWeeksLimit: 0,
							dateMonthsLimit: 0,
							dateQuarterLimit: 0,
							dateYearsLimit: 0,
							dateType: "MONTH",
						}}
					/>
					<ReportsTable
						response={data}
						generateTableRequestBody={generateTableRequestBody}
						csvUrl=''
						displayExpandibleRowTable={false}
						groupColumns={null}
						triggerGetReport={triggerGetReport}
						isLoading={isLoading}
						isError={isError}
						error={error}
					/>
				</div>
			) : (
				<UnauthorizedPage />
			)}
		</Spin>
	);
};

export default ReportFaultGeneral;
