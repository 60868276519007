import React from "react";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { faBan, faCircleCheck, faPlugCircleCheck } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import { isMobile } from "react-device-detect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tag } from "antd";
import DrawerRowItem from "./DrawerRowItem";
import DrawerRowDivider from "./DrawerRowDivider";
// INTERFACES
import { RootState } from "../../../app/store";

function DrawerMessageInformation({ data }: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div style={{ overflowX: "auto" }}>
			<br />
			{/** ************************************************* */}
			{/** COMPONENT DRAWER DIVIDER */}
			<DrawerRowDivider
				icon={faPlugCircleCheck}
				title={t("configuration.message.messageInformation")}
			/>
			{/** ------------------------------------------------------------- */}
			{/** Message  */}
			<div className={`drawer__box__${theme}`}>
				{/* ID */}
				<DrawerRowItem title='ID' value={data && data.id ? data.id : "--"} />
				{/* SHORT MESSAGE */}
				<DrawerRowItem
					title={t("configuration.message.shortMessage")}
					value={data && data.short_message ? data.short_message : "--"}
				/>
				{/* DESCRIPTION */}
				<DrawerRowItem
					title={t("configuration.message.description")}
					value={data && data.description ? data.description : "--"}
				/>
				{/* STATUS */}
				<div className='generalStyles__flex'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
							{t("configuration.message.position")}
						</p>
					</div>
					<div className='drawer__textContainer'>
						<p className='drawer__text'>
							{data && data.position ? (
								<Tag
									color='success'
									icon={
										<FontAwesomeIcon
											icon={faCircleCheck}
											className='generalStyles__mrFix'
										/>
									}
									className='drawer__tag'
									style={{ width: "108px", textAlign: "center" }}
								>
									{t("configuration.message.yes").toUpperCase()}
								</Tag>
							) : (
								<Tag
									color='error'
									icon={
										<FontAwesomeIcon
											icon={faBan}
											className='generalStyles__mrFix'
										/>
									}
									className='drawer__tag'
									style={{ width: "108px", textAlign: "center" }}
								>
									{t("configuration.message.no").toUpperCase()}
								</Tag>
							)}
						</p>
					</div>
				</div>
				{/* ENUM ID */}
				<DrawerRowItem
					title={t("configuration.message.enumId")}
					value={data && data.enum_id ? data.enum_id : "--"}
				/>
				{/* TYPE */}
				<DrawerRowItem
					title={t("configuration.message.type")}
					value={data && data.message_type && data.message_type.name ? data.message_type.name : "--"}
				/>
			</div>
		</div>
	);
}

export default DrawerMessageInformation;
