// INTERFACES
import { ReportChartData } from "../../interfaces/Reports.interface";

export const getChartTextColor = (theme: string) => {
	if (theme === "dark") {
		return "white";
	}
	if (theme === "light") {
		return "#2968c0";
	}
	if (theme === "blue") {
		return "white";
	}
	return "black";
};

export const getChartBackgroundColor = (theme: string) => {
	if (theme === "dark") {
		return "black";
	}
	if (theme === "light") {
		return "white";
	}
	if (theme === "blue") {
		return "#0c1a28";
	}
	return "";
};

export const getChartTicklineColor = (theme: string) => {
	if (theme === "dark") {
		return "#4f4f4f";
	}
	if (theme === "light") {
		return "#206feb";
	}
	if (theme === "blue") {
		return "#4f4f4f";
	}
	return "";
};

export const getChartAxisHighlight = (theme: string) => {
	if (theme === "dark") {
		return "white";
	}
	if (theme === "light") {
		return "#2968c0";
	}
	if (theme === "blue") {
		return "white";
	}
	return "";
};

export const getTotalCarsInBank = (data: ReportChartData[]) => {
	const copy: ReportChartData[] = structuredClone(data);
	const cars: string[] = [];
	copy.map((item: ReportChartData) => cars.push(item.tag));
	const uniqueCars = cars.filter(
		(item: string, index: number) => cars.indexOf(item) === index
	);
	const removeBlankCars = uniqueCars.filter((car) => car !== "");
	return removeBlankCars;
};

const sortDataByDay = (data: ReportChartData[]) => {
	data.sort((a: any, b: any) => {
		// Convert day strings to format "DD/MM" to compare properly
		const dateA = a.day.split("/").reverse().join("/");
		const dateB = b.day.split("/").reverse().join("/");
		// Compare dates
		if (dateA < dateB) {
			return -1;
		}
		if (dateA > dateB) {
			return 1;
		}
		return 0;
	});
	return data;
};

const generateMirrorData = (data: ReportChartData[]) => {
	const copy: ReportChartData[] = structuredClone(data);
	const newData: any = [];
	copy.map((item: any) => {
		const itemCopy = structuredClone(item);
		const num = item.count;
		itemCopy.count = item.lift_name.toLowerCase() === "down" ? -num : num;
		newData.push(itemCopy);
		return true;
	});
	return newData;
};

export const getAxisLabels = (data: ReportChartData[]) => {
	const xAxisData: string[] = [];
	data.map((item: any) => xAxisData.push(item.name));
	const uniqueArrayXAxisData = xAxisData.filter(
		(item: string, index: number) => xAxisData.indexOf(item) === index
	);
	return uniqueArrayXAxisData;
};

export const generatePieDataByMonth = (
	data: ReportChartData[],
	carsSelected: string[]
) => {
	const copy: ReportChartData[] = structuredClone(data);
	// Remove all unselected cars
	const newData: ReportChartData[] = [];
	copy.map((item: ReportChartData) => {
		if (item && item.tag && carsSelected.includes(item.tag)) {
			newData.push(item);
		}
		return true;
	});
	// Generate unique cars
	const cars: string[] = [];
	newData.map((item: ReportChartData) => cars.push(item.tag));
	return "";
};

export const generatePieData = (
	data: ReportChartData[],
	carsSelected: string[]
) => {
	// Sort data by date
	let copy = structuredClone(data);
	copy = sortDataByDay(copy);
	// Remove all unselected cars
	const newData: ReportChartData[] = [];
	copy.map((item: ReportChartData) => {
		if (item && item.tag && carsSelected.includes(item.tag)) {
			newData.push(item);
		}
		return true;
	});
	// Generate xAxisData
	const uniqueArrayAxisData = getAxisLabels(newData);
	// Generate pie chart data
	const pieData: any = [];
	uniqueArrayAxisData.map((item: string) => {
		const newArr: any = [];
		newData.map((item2: any) => {
			if (item2.day === item) {
				newArr.push({ label: item2.lift_name, value: item2.count });
			}
			return true;
		});
		pieData.push({ label: item, data: newArr });
		return true;
	});
	return pieData;
};

export const generateBarsDataFault = (
	data: any,
	generateStack: boolean,
	carsSelected: any,
	mirror: boolean
) => {
	let copy: ReportChartData[];
	copy = structuredClone(data);
	// Generate Mirror Data
	if (mirror) {
		const responseMirror = generateMirrorData(data);
		copy = structuredClone(responseMirror);
	}
	// Sort data by date
	// Remove all unselected cars
	const newData: any = [];
	copy.map((item: any) => {
		if (item && item.tag && carsSelected.includes(item.tag)) {
			newData.push(item);
		} else {
			newData.push({
				name: item.name,
				tag: item.tag,
				value: 0,
			});
		}
		return true;
	});
	// Generate Series Data
	const cars: string[] = [];
	newData.map((item: ReportChartData) => cars.push(item.tag));
	const uniqueCars = cars.filter(
		(item: string, index: number) => cars.indexOf(item) === index
	);
	// Generate xAxisData
	const uniqueArrayAxisData = getAxisLabels(newData);
	const seriesData: any = [];
	// Extract unique cars to generate series array
	uniqueCars.map((label: string) => {
		if (label !== "" && carsSelected.includes(label)) {
			const seriesBody: any = {
				data: uniqueArrayAxisData,
				label,
				highlightScope: {
					highlighted: "item",
					faded: "global",
				},
			};
			if (generateStack) {
				seriesBody.stack = "A";
			}
			if (label === "UP") {
				seriesBody.color = "#00e300";
			}
			if (label === "DOWN") {
				seriesBody.color = "#e70000";
			}
			if (label.includes("Moving Normally_")) {
				seriesBody.color = "#1bed18";
			}
			if (label.includes("High Speed_")) {
				seriesBody.color = "#0c04e2";
			}
			if (label.includes("Sleep Mode")) {
				seriesBody.color = "#8cf8f6";
			}
			if (label.includes("Out of Service_")) {
				seriesBody.color = "#e50509";
			}
			if (label.includes("Maintenance_")) {
				seriesBody.color = "#1e1e1e";
			}
			if (label.includes("Not Monitored_")) {
				seriesBody.color = "#eff635";
			}
			if (label.includes("Inactive_")) {
				seriesBody.color = "#ef0cef";
			}
			seriesData.push(seriesBody);
		}
		return true;
	});
	// Fill series data with count fron API
	const copySeriesData = structuredClone(seriesData);
	for (let index1 = 0; index1 < seriesData.length; index1 += 1) {
		const newCopy = structuredClone(seriesData[index1].data);
		newCopy.map((item: any, itemIndex1: number) => {
			newData.map((item2: any) => {
				if (item === item2.name && copySeriesData[index1].label === item2.tag) {
					newCopy[itemIndex1] = item2.value;
				}
				return true;
			});
			return true;
		});
		copySeriesData[index1].data = newCopy;
	}
	// Replace all string by 0
	copySeriesData.map((serie: any, indexSerie: number) => {
		const newArray = serie.data.map((item: any) =>
			typeof item === "string" ? 0 : item
		);
		copySeriesData[indexSerie].data = newArray;
		return true;
	});
	return copySeriesData;
};

export const generateLinesData = (
	data: ReportChartData[],
	curveType: string,
	carsSelected: string[],
	mirror: boolean
) => {
	let copy: ReportChartData[];
	copy = structuredClone(data);
	// Generate Mirror Data
	if (mirror) {
		const responseMirror = generateMirrorData(data);
		copy = structuredClone(responseMirror);
	}
	// Sort data by date
	// Remove all unselected cars
	const newData: any = [];
	copy.map((item: any) => {
		if (item && item.tag && carsSelected.includes(item.tag)) {
			newData.push(item);
		} else {
			newData.push({
				name: item.name,
				tag: item.tag,
				value: 0,
			});
		}
		return true;
	});
	// Generate xAxisData
	const uniqueArrayAxisData = getAxisLabels(newData);
	// Generate Series Data
	const cars: string[] = [];
	newData.map((item: any) => cars.push(item.tag));
	const uniqueCars = cars.filter(
		(item: string, index: number) => cars.indexOf(item) === index
	);
	const seriesData: any = [];
	// Extract unique cars to generate series array
	uniqueCars.map((label: string) => {
		const seriesBody: any = {
			data: uniqueArrayAxisData,
			label,
			curve: curveType,
			highlightScope: {
				highlighted: "item",
				faded: "global",
			},
		};
		if (label === "UP") {
			seriesBody.color = "#00e300";
		}
		if (label === "DOWN") {
			seriesBody.color = "#e70000";
		}
		seriesData.push(seriesBody);
		return true;
	});
	// Fill series data with count fron API
	const copySeriesData = structuredClone(seriesData);
	for (let index1 = 0; index1 < seriesData.length; index1 += 1) {
		const newCopy = structuredClone(seriesData[index1].data);
		newCopy.map((item: any, itemIndex1: number) => {
			newData.map((item2: any) => {
				if (item === item2.name && copySeriesData[index1].label === item2.tag) {
					newCopy[itemIndex1] = item2.value;
				}
				return true;
			});
			return true;
		});
		copySeriesData[index1].data = newCopy;
	}
	// Replace all string by 0
	copySeriesData.map((serie: any, indexSerie: number) => {
		const newArray = serie.data.map((item: any) =>
			typeof item === "string" ? 0 : item
		);
		copySeriesData[indexSerie].data = newArray;
		return true;
	});
	return copySeriesData;
};

export const GenerateWaitTimeByDayData = (data: any) => {
	const newData: any = [];
	if (data) {
		data.map((item: any) => {
			newData.push({
				count: item.seconds,
				lift_name: item.direction,
				floor: item.hours,
			});
			return true;
		});
	}
	return newData;
};

export const GenerateDistributionDirectionData = (data: any) => {
	const newUpData: any = [];
	const newDownData: any = [];

	if (data) {
		data.map((item: any) => {
			if (item.direction === "UP") {
				newUpData.push({
					value: item.counts,
					tag: "Counts",
					name: item.category,
				});
				newUpData.push({
					value: item.percentage,
					tag: "Percentage",
					name: item.category,
				});
				newUpData.push({
					value: item.total,
					tag: "Total",
					name: item.category,
				});
			} else if (item.direction === "DOWN") {
				newDownData.push({
					value: item.counts,
					tag: "Counts",
					name: item.category,
				});
				newDownData.push({
					value: item.percentage,
					tag: "Percentage",
					name: item.category,
				});
				newDownData.push({
					value: item.total,
					tag: "Total",
					name: item.category,
				});
			}
			return true;
		});
	}
	return [newUpData, newDownData];
};

export const GenerateDistributionTotalData = (data: any) => {
	const newData: any = [];
	if (data) {
		data.map((item: any) => {
			newData.push({
				value: item.counts,
				tag: "Counts",
				name: item.category,
			});
			newData.push({
				value: item.percentage,
				tag: "Percentage",
				name: item.category,
			});
			newData.push({
				value: item.total,
				tag: "Total",
				name: item.category,
			});
			return true;
		});
	}
	return newData;
};

export const generateChartData = (data: any) => {
	const newData: any = [];
	if (data) {
		data.map((item: any) => {
			// Lift_name and Fault
			if (item && item.lift_name && item.fault) {
				newData.push({
					value: item.count,
					tag: item.fault,
					name: item.lift_name,
				});
			}
			// Lift_name and Floor name
			if (item && item.lift_name && item.floor_name) {
				newData.push({
					value: item.count,
					tag: item.lift_name,
					name: item.floor_name,
				});
			}
			// Lift_name and Day
			if (item && item.lift_name && item.day) {
				newData.push({
					value: item.count,
					tag: item.lift_name,
					name: item.day,
				});
			}
			// Category and Hours
			if (item && item.hours && item.category) {
				newData.push({
					value: item.count,
					tag: item.category,
					name: item.hours,
				});
			}
			// Category and floor name
			if (item && item.floor_name && item.category) {
				newData.push({
					value: item.count,
					tag: item.category,
					name: item.floor_name,
				});
			}
			// Direction and floor name
			if (item && item.floor_name && item.direction) {
				newData.push({
					value: item.count,
					tag: item.direction,
					name: item.floor_name,
				});
			}
			// Direction and hours
			if (item && item.hours && item.direction) {
				newData.push({
					value: item.count,
					tag: item.direction,
					name: item.hours,
				});
			}
			return true;
		});
	}
	return newData;
};

export const convertSecondsToCount = (data: any) => {
	const newData: any = [];
	data.map((item: any) =>
		newData.push({
			...item,
			count: item.seconds,
			seconds: undefined,
		})
	);
	return newData;
};

export const GenerateCarServiceServiceLogs = (data: any) => {
	const newData: any = [];
	if (data) {
		data.map((lift: any) => {
			if (lift && lift.logs) {
				lift.logs.map((log: any, logIndex: number) => {
					const [whole, decimal] = log.duration.split(":").map(Number);
					newData.push({
						name: lift.lift_name,
						value: parseFloat(`${whole}.${decimal}`),
						tag: `${log.mode}_${logIndex}`,
						// lift_name: log.mode,
						startTime: log.start,
						endTime: log.end,
					});
					return true;
				});
			}
			return true;
		});
	}
	return newData;
};

export const GenerateCarUseComparisons = (data: any) => {
	const newData: any = [];
	if (data) {
		data.map((item: any) => {
			if (item && item.lift_name) {
				newData.push({
					value: item.carcalls,
					name: item.lift_name,
					tag: "carcalls",
				});
				newData.push({
					value: item.cycles,
					name: item.lift_name,
					tag: "cycles",
				});
				newData.push({
					value: item.hallcalls,
					name: item.lift_name,
					tag: "hallcalls",
				});
				newData.push({
					value: item.reopens,
					name: item.lift_name,
					tag: "reopens",
				});
				newData.push({
					value: item.starts,
					name: item.lift_name,
					tag: "starts",
				});
			}
			return true;
		});
	}
	return newData;
};

export const GenerateCarUseTimes = (data: any) => {
	const newData: any = [];
	if (data) {
		data.map((item: any) => {
			newData.push({
				value: parseFloat(item.close),
				name: item.lift_name,
				tag: "close",
			});
			newData.push({
				value: parseFloat(item.closecc),
				name: item.lift_name,
				tag: "closecc",
			});
			newData.push({
				value: parseFloat(item.dwell),
				name: item.lift_name,
				tag: "dwell",
			});
			newData.push({
				value: parseFloat(item.dwellhc),
				name: item.lift_name,
				tag: "dwellhc",
			});
			newData.push({
				value: parseFloat(item.open),
				name: item.lift_name,
				tag: "open",
			});
			newData.push({
				value: parseFloat(item.openhc),
				name: item.lift_name,
				tag: "openhc",
			});
			return true;
		});
	}
	return newData;
};

export const GenerateDistributionDayData = (data: any) => {
	const newUpData: any = [
		{ value: 0, tag: "0 to 10 Seconds", name: "1" },
		{ value: 0, tag: "11 to 30 Seconds", name: "1" },
		{ value: 0, tag: "31 to 50 Seconds", name: "1" },
		{ value: 0, tag: "51 to 70 Seconds", name: "1" },
		{ value: 0, tag: "71 to 90 Seconds", name: "1" },
		{ value: 0, tag: "Over 90 Seconds", name: "1" },
	];
	const newDownData: any = [
		{ value: 0, tag: "0 to 10 Seconds", name: "1" },
		{ value: 0, tag: "11 to 30 Seconds", name: "1" },
		{ value: 0, tag: "31 to 50 Seconds", name: "1" },
		{ value: 0, tag: "51 to 70 Seconds", name: "1" },
		{ value: 0, tag: "71 to 90 Seconds", name: "1" },
		{ value: 0, tag: "Over 90 Seconds", name: "1" },
	];
	if (data) {
		data.map((item: any) => {
			if (item.direction === "UP") {
				newUpData.push({
					value: item.percentage,
					tag: item.category,
					name: item.hours.toString(),
				});
			} else if (item.direction === "DOWN") {
				newDownData.push({
					value: item.percentage,
					tag: item.category,
					name: item.hours.toString(),
				});
			}
			return true;
		});
	}
	return [newUpData, newDownData];
};
