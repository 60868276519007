import React from "react";
// REDUX
import { useSelector } from "react-redux";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// COMPONENTS
import ReportsFilterDateRange from "../filters/ReportsFilterDateRange";
import ReportsFilterDateDay from "../filters/ReportsFilterDateDay";
import ReportsFilterDateWeek from "../filters/ReportsFilterDateWeek";
import ReportsFilterDateMonth from "../filters/ReportsFilterDateMonth";
import ReportsFilterDateQuarter from "../filters/ReportsFilterDateQuarter";
import ReportsFilterDateYear from "../filters/ReportsFilterDateYear";
import ReportsFilterDateType from "../filters/ReportsFilterDateType";
import ReportsFilterSelectSeconds from "../filters/ReportsFilterSelectSeconds";

// INTERFACES
import { RootState } from "../../../app/store";

function ReportsDrawerDateAndTimeFilters() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);
	const { filters } = useSelector((state: RootState) => state.report);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className={`drawer__box__${theme}`}>
			{/* START DATE */}
			<div className='generalStyles__flex'>
				<div className='generalStyles__inputFlexNameLarge'>
					<span className='generalStyles__inputFlexRequired'>*</span>
					<span className='drawer__title'>{t("general.date")}:</span>
				</div>
				<div className='drawer__textContainer generalStyles__width100'>
					{filters.displayDateByRange ? (
						<ReportsFilterDateRange />
					) : (
						<>
							{filters.dateType === "TODAY" && <ReportsFilterDateDay />}
							{filters.dateType === "WEEK" && <ReportsFilterDateWeek />}
							{filters.dateType === "MONTH" && <ReportsFilterDateMonth />}
							{filters.dateType === "QUARTER" && <ReportsFilterDateQuarter />}
							{filters.dateType === "YEAR" && <ReportsFilterDateYear />}
						</>
					)}
				</div>
			</div>
			{/* SELECT */}
			<div className='generalStyles__flex mt-3'>
				<div className='generalStyles__inputFlexNameLarge'>
					<span className='generalStyles__inputFlexRequired'>*</span>
					<span className='drawer__title'>{t("general.dateType")}:</span>
				</div>
				<div className='drawer__textContainer generalStyles__width100'>
					<ReportsFilterDateType />
				</div>
			</div>
			{/* TIME IN SECONDS */}
			{filters.displayMinimumActivation && (
				<div className='generalStyles__flex mt-3'>
					<div className='generalStyles__inputFlexNameLarge'>
						<span className='generalStyles__inputFlexRequired'>*</span>
						<span className='drawer__title'>Minimum Activation:</span>
					</div>
					<div className='drawer__textContainer generalStyles__width100'>
						<ReportsFilterSelectSeconds />
					</div>
				</div>
			)}
		</div>
	);
}

export default ReportsDrawerDateAndTimeFilters;
