import React, { useState, useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Spin } from "antd";
// REDUX
import { useSelector } from "react-redux";
import { usePostCarServiceLogMutation } from "../../../redux/reports/chartsAPI";
// COMPONENTS
import ReportsTable from "../ReportsTable";
import ReportsHeader from "../ReportsHeader";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";
// INTERFACES
import { RootState } from "../../../app/store";

const ReportCarServiceServiceLog = () => {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [loadPage, setLoadPage] = useState(false);
	const [customData, setCustomData] = useState({});

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);
	const { theme } = useSelector((state: RootState) => state.home);
	const { filters } = useSelector((state: RootState) => state.report);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetReport, { data, isLoading, isError, error }] =
		usePostCarServiceLogMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const buildCustomData = (results: any) => {
		const newData: any = [];
		results.results.map((lift: any) => {
			if (lift && lift.logs) {
				lift.logs.map((record: any) => {
					newData.push({
						lift_name: lift.lift_name,
						date: record.date,
						duration: record.duration,
						end: record.end,
						mode: record.mode,
						start: record.start,
					});
					return true;
				});
			}
			return true;
		});
		setCustomData({ results: newData });
	};

	// eslint-disable-next-line no-unused-vars
	const generateTableRequestBody = ({ downloadReport }: any) => {
		const body = {
			bank_id: filters.bankId,
			start_date: filters.startDate,
			end_date: filters.endDate,
			timezone: filters.timezoneCode,
		};
		return body;
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		setLoadPage(true);
		setTimeout(() => {
			setLoadPage(false);
		}, 1000);
	}, []);

	useEffect(() => {
		if (data && data.results && data.results.length > 0) {
			buildCustomData(data);
		}
	}, [data]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Spin spinning={loadPage}>
			{validatePermission("permission-not-defined", permissions) ? (
				<div className={`layout__contentPage__${theme}`}>
					<ReportsHeader
						reportDefaultData={{
							reportType: "REPORT",
							levelTypeName: "BANKS",
							displaySelectTypeLevel: false,
							liftType: "ALL",
							defaultColumns: [
								"lift_name",
								"date",
								"duration",
								"end",
								"mode",
								"start",
							],
							defaultOffsets: [],
							infoDescriptionText: "report.infoDescriptionText2",
							displayDownloadCSVReport: false,
							displayDownloadPDFReport: true,
							reportTitle: "Car Service / Service Log",
							displayAdvanceFiltersExclusions: false,
							displayAdvanceFiltersOffsets: false,
							disabledOffsets: true,
							useTablePagination: false,
							displayDateByRange: true,
							displayDate: true,
							displaySelectLiftType: true,
							displayAdvanceFilterCharts: false,
							displayAdvanceFiltersTable: false,
							displayRecentWeeksPicket: false,
							displayInputLimitItems: false,
							displayChartMirror: false,
							chartMirror: false,
							chartType: "bar_stack_vertical",
							dateTypeOptions: [
								{ id: "TODAY", name: "Day" },
								{ id: "WEEK", name: "Week" },
								{ id: "MONTH", name: "Month" },
								{ id: "QUARTER", name: "Quarter" },
								{ id: "YEAR", name: "Year" },
							],
							dateDaysLimit: 0,
							displayMinimumActivation: false,
							dateWeeksLimit: 0,
							dateMonthsLimit: 0,
							dateQuarterLimit: 0,
							dateYearsLimit: 0,
							dateType: "MONTH",
						}}
					/>
					<ReportsTable
						response={customData}
						generateTableRequestBody={generateTableRequestBody}
						csvUrl=''
						displayExpandibleRowTable={false}
						groupColumns={null}
						triggerGetReport={triggerGetReport}
						isLoading={isLoading}
						isError={isError}
						error={error}
					/>
				</div>
			) : (
				<UnauthorizedPage />
			)}
		</Spin>
	);
};

export default ReportCarServiceServiceLog;
