import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Spin, Divider, Drawer, Button, Upload } from "antd";
import { UploadOutlined } from '@ant-design/icons';
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEthernet,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateOpenImportKeys } from "../../../../redux/keys/keysSlice";
import {
	useImportKeyMutation,
} from "../../../../redux/keys/keysAPI";
// COMPONENTS
import GlobalAlert2 from "../../../home/GlobalAlert2";
// AUTHORIZATION
import GETJwtToken from "../../../../redux/authentication/authentication";
// INTERFACES
import { KeyImportInterface } from "../../../../interfaces/Key.interface";
import { RootState } from "../../../../app/store";

function KeysImportForm() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const dispatch = useDispatch();
	const BLANK_KEY: KeyImportInterface = {
		adapter_id: "",
		csv: undefined
	};

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [keyImport, setKeyImport] = useState<KeyImportInterface>(BLANK_KEY);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [csvFile, setCSVFile] = useState<File | undefined>(undefined);
	const [placeholderFiles, setPlaceholderFiles] = useState<any[]>([]);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { keysAdapter } = useSelector((state: RootState) => state.adapter);
	const { theme } = useSelector((state: RootState) => state.home);
	const {
		openImportKeys,
	} = useSelector((state: RootState) => state.key);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		importKey,
		{
			isSuccess: isSuccessImportKey,
			isLoading: isLoadingImportKey,
			isError: isErrorImportKey,
			error: errorImportKey,
			reset: resetImportKey,
		},
	] = useImportKeyMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const showIsLoading = () => {
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
		}, 1000);
	};

	const onClickClose = () => {
		dispatch(updateOpenImportKeys(false));
		resetImportKey();
		setCSVFile(undefined);
		setKeyImport(BLANK_KEY);
	};

	const postImportKey = async () => {
		const token = await GETJwtToken();
		const BODY: any = {
			adapter_id: keyImport.adapter_id,
			csv: keyImport.csv
		};

		importKey({
			token,
			body: BODY,
		});
	};

	const onClickSave = async () => {
		postImportKey();
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		resetImportKey();
		setCSVFile(undefined);
		setKeyImport(BLANK_KEY);
		showIsLoading();
	}, [openImportKeys]);

	useEffect(() => {
		onClickClose();
	}, [
		isSuccessImportKey,
		isErrorImportKey,
	]);

	useEffect(() => {
		const copy: KeyImportInterface = structuredClone(keyImport);
		copy.adapter_id = keysAdapter.id || ""
		copy.csv = csvFile
		setKeyImport(copy);
	}, [csvFile]);

	useEffect(() => {
		if (csvFile) {
			setPlaceholderFiles([csvFile])
		}
		else {
			setPlaceholderFiles([])
		}
	}, [csvFile])

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width='35%'
			placement='right'
			onClose={onClickClose}
			closable={false}
			open={openImportKeys}
		>
			<Spin spinning={isLoadingImportKey || isLoading}>
				<div>
					{/** ------------------------------------------------------------- */}
					{/** GLOBAL DATA  */}
					<div>
						<Divider
							orientation='left'
							className='generalStyles__drawerDivider'
						>
							<h5>
								<FontAwesomeIcon
									icon={faEthernet}
									className='generalStyles__info generalStyles__mrFix'
								/>
								<>{t("key.importKeys")}</>
							</h5>
						</Divider>
					</div>
					{/** ------------------------------------------------------------- */}
					{/** KEY FORM  */}
					<div className={`drawer__box__${theme}`}>
						<div className='col-md-12'>
							<div className='generalStyles__flex'>
								<div className='generalStyles__inputFlexNameLarge'>
									<span className='generalStyles__inputFlexRequired'>*</span>
									<span>{t("key.CSVFile")}</span>
								</div>
								<div className='generalStyles__width100'>
									{/* CSV FILE */}
									<Upload
										fileList={placeholderFiles}
										accept=".csv"
										beforeUpload={(file) => { setCSVFile(file); return false; }}
										onRemove={() => setCSVFile(undefined)}
										maxCount={1}>
										<Button icon={<UploadOutlined />}>{t("key.selectCSV")}</Button>
									</Upload>
								</div>
								<div className='generalStyles__infoTooltipIconBlank' />
							</div>
						</div>
					</div>
					<br />
				</div>
				{/** ---------------------------------------------------- */}
				{/** CLOSE AND SAVE BUTTON */}
				<div className='mt-4'>
					<div>
						<Button
							type='default'
							onClick={onClickClose}
							className='buttonStyle__3'
						>
							{t("edgeManagement.close")}
						</Button>
						<Button
							type='primary'
							className='buttonStyle__3'
							onClick={onClickSave}
							disabled={keyImport.adapter_id === "" || !keyImport.csv}
						>
							{t("general.save")}
						</Button>
					</div>
				</div>
			</Spin>
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2
				isError={isErrorImportKey}
				isSuccess={isSuccessImportKey}
				requestType='POST'
				error={errorImportKey}
				name='Key'
			/>
		</Drawer>
	);
}

export default KeysImportForm;
