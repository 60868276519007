/* eslint-disable */
import React, { useEffect } from "react";
// ANT DESIGN COMPONENTS
import { Tag, Card, Tooltip } from "antd";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faElevator, faKey } from "@fortawesome/free-solid-svg-icons";
// COMPONENTS
// INTERFACES
import {
	MonitoringServiceViewInterface,
	LiftInterface,
} from "../../../interfaces/EdgeManagement.interface";

function MonitoringServiceView({
	elevatorSocketMessage,
	escalatorSocketMessage,
	movingWalkSocketMessage,
	layout,
}: MonitoringServiceViewInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const serviceMessage = ["CTL", "RTL", "IND", "OTS", "HEO", "EOF"];
	const serviceColor = ["magenta", "orange", "green", "blue", "purple", "red"];

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div style={{ padding: "10px" }}>
			{layout && layout.lifts && (
				<div>
					<div>
						{layout.lifts.map((lift: LiftInterface) => (
							<Card
								style={{
									height: "40px",
									marginBottom: "5px",
								}}
							>
								<div
									className='generalStyles__flex'
									style={{ marginTop: "-17px" }}
								>
									<div style={{ width: "120px" }}>
										<p
											className='generalStyles__textEllipsis'
											style={{ width: "150px" }}
										>
											<FontAwesomeIcon
												className='generalStyles__info generalStyles__mrFix'
												icon={faElevator}
											/>
											{lift.name || "--"}
										</p>
									</div>
									<div style={{ width: "100px" }}>
										<Tooltip title='Example tooltip from emulator, Service view'>
											<Tag
												style={{ width: "90px", textAlign: "center" }}
												color={serviceColor[Math.floor(Math.random() * 5)]}
											>
												{serviceMessage[Math.floor(Math.random() * 5)]}
											</Tag>
										</Tooltip>
									</div>
								</div>
							</Card>
						))}
					</div>
				</div>
			)}
		</div>
	);
}

export default MonitoringServiceView;
