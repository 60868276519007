import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Drawer, Button } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import {
	faUserShield,
	faBuildingCircleCheck,
	faUserLock,
	faCheckCircle,
	faBan,
} from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenDrawerKey } from "../../../../redux/keys/keysSlice";
// COMPONENTS
import DrawerMessageInformation from "../../../components/drawer/DrawerMessagInformation";
import DrawerAdapterInformation from "../../../components/drawer/DrawerAdapterInformation";
import DrawerKeyInformation from "../../../components/drawer/DrawerKeyInformation";

library.add(
	faUserShield,
	faBuildingCircleCheck,
	faUserLock,
	faCheckCircle,
	faBan
);

function KeysDrawer() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [data, setData] = useState<any>({});

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { key, openDrawerKey } = useSelector(
		(state: any) => state.key
	);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickClose = () => {
		dispatch(updateOpenDrawerKey(false));
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (openDrawerKey) {
			if (key) {
				setData(key);
			}
		}
	}, [openDrawerKey]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width='35%'
			placement='right'
			onClose={onClickClose}
			closable={false}
			open={openDrawerKey}
		>
			{data && (
				<div>
					{/** ************************************************* */}
					{/** KEY INFORMATION */}
					<DrawerKeyInformation data={key || {}} />
					<br />
					{/** ************************************************* */}
					{/** ADAPTER INFORMATION */}
					<DrawerAdapterInformation
						data={key && key.adapter ? key.adapter : {}}
					/>
					<br />
					{/** ************************************************* */}
					{/** MESSAGE INFORMATION */}
					<DrawerMessageInformation
						data={key && key.message ? key.message : {}}
					/>
					<br />
				</div>
			)}
			{/** ************************************************* */}
			{/** CLOSE AND SUBMIT BUTTONS */}
			<div className='mt-4 generalStyles__width100'>
				<div>
					<Button
						type='default'
						onClick={onClickClose}
						className='buttonStyle__5'
					>
						{t("organizationManagement.close")}
					</Button>
				</div>
			</div>
		</Drawer>
	);
}

export default KeysDrawer;
