import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";

const maxRetries = 2;

const baseQuery = retry(fetchBaseQuery(), { maxRetries });

export const notificationAPI = createApi({
	reducerPath: "notificationAPI",
	baseQuery,
	keepUnusedDataFor: 0,
	tagTypes: [],
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	endpoints: (builder) => ({
		//* *********************************************************** */
		//* GET BANKS NOTIFICATIONS */
		getBanks: builder.query({
			query: (body: any) => ({
				url: `${
					process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE
				}/banks/?&page=1&limit=5${body.searchAndFilterString || ""}`,
				headers: { authorization: body.token },
			}),
			providesTags: [],
		}),
		//* *********************************************************** */
		//* GET ALERTS */
		getAlerts: builder.query({
			query: (body: any) => ({
				url: `${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/banks/?&page=1&limit=10`,
				headers: { authorization: body.token },
			}),
			providesTags: [],
		}),
		//* *********************************************************** */
		//* GET ALERTS */
		getUsers: builder.query({
			query: (body: any) => ({
				url: `${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/banks/?&page=1&limit=10`,
				headers: { authorization: body.token },
			}),
			providesTags: [],
		}),
		//* *********************************************************** */
		//* GET ALERTS */
		getSchedules: builder.query({
			query: (body: any) => ({
				url: `${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/banks/?&page=1&limit=10`,
				headers: { authorization: body.token },
			}),
			providesTags: [],
		}),
	}),
});

export const {
	useLazyGetBanksQuery,
	useLazyGetAlertsQuery,
	useLazyGetUsersQuery,
	useLazyGetSchedulesQuery,
} = notificationAPI;
