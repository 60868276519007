import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Drawer, Divider, Spin, Checkbox, Tag } from "antd";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faPlugCircleCheck } from "@fortawesome/free-solid-svg-icons";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateOpenDrawerAssignActions } from "../../../redux/adapters/adapterSlice";
import { useLazyGetActionsQuery } from "../../../redux/actions/actionsAPI";
import { usePutAssignActionMutation } from "../../../redux/adapters/adapterAPI";
// COMPONENTS
import DrawerRowItem from "../../components/drawer/DrawerRowItem";
import DrawerCloseSaveButton from "../../components/drawer/DrawerCloseSaveButton";
import GlobalAlert2 from "../../home/GlobalAlert2";
// INTERFACES
import { RootState } from "../../../app/store";
import {
	ActionAdapterInterface,
	ActionInterface,
} from "../../../interfaces/Actions.interface";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";

function AdaptersAssignActions() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [actions, setActions] = useState<ActionAdapterInterface[]>([]);
	const [previousAssignedAcctions, setPreviousAssignedActions] = useState<
		string[]
	>([]);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { openDrawerAssignActions, adapter } = useSelector(
		(state: RootState) => state.adapter
	);
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		triggerGetActions,
		{ data: dataGetActions, isLoading: isLoadingGetActions },
	] = useLazyGetActionsQuery();

	const [
		triggerPutActions,
		{
			isLoading: isLoadingPutActions,
			isSuccess: isSuccessPutActions,
			isError: isErrorPutActions,
			error: errorPutActions,
			reset: resetPutActions,
		},
	] = usePutAssignActionMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onClickClose = () => {
		dispatch(updateOpenDrawerAssignActions(false));
		resetPutActions();
	};

	const getInitialData = async () => {
		const token = await GETJwtToken();
		triggerGetActions({
			token,
		});
	};

	const buildActions = () => {
		// Generate first actions object
		const copy: ActionAdapterInterface[] = structuredClone(dataGetActions.data);
		copy.map((action: ActionAdapterInterface, index: number) => {
			copy[index].isActive = false;
			return true;
		});
		setActions(copy);
	};

	const onChangeCheckbox = (e: any, id: string) => {
		const index = actions.findIndex(
			(object: ActionInterface) => object.id === id
		);
		if (index >= 0) {
			const copy: ActionAdapterInterface[] = structuredClone(actions);
			copy[index].isActive = e.target.checked;
			setActions(copy);
		}
	};

	const onClickSave = async () => {
		const addActions: string[] = [];
		actions.map((action: ActionAdapterInterface) => {
			if (action.isActive) {
				addActions.push(action.id);
			}
			return true;
		});
		const token = await GETJwtToken();
		const removeActions = previousAssignedAcctions.filter(
			(item) => !addActions.includes(item)
		);
		triggerPutActions({
			id: adapter.id,
			token,
			body: { actions: addActions, actions2delete: removeActions },
		});
	};

	const markCheckedActions = () => {
		const copy: ActionAdapterInterface[] = structuredClone(actions);
		copy.map((item: ActionAdapterInterface, index: number) => {
			copy[index].isActive = false;
			previousAssignedAcctions.map((actionId: string) => {
				if (item.id === actionId) {
					copy[index].isActive = true;
				}
				return true;
			});
			return true;
		});
		setActions(copy);
	};
	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		getInitialData();
	}, [openDrawerAssignActions]);

	useEffect(() => {
		if (dataGetActions && dataGetActions.data) {
			buildActions();
		}
	}, [dataGetActions]);

	useEffect(() => {
		onClickClose();
	}, [isSuccessPutActions, isErrorPutActions]);

	useEffect(() => {
		if (adapter && adapter.action_catalogs) {
			const newArr: string[] = [];
			adapter.action_catalogs.map((item: ActionInterface) => {
				newArr.push(item.id);
				return true;
			});
			setPreviousAssignedActions(newArr);
		}
	}, [adapter]);

	useEffect(() => {
		markCheckedActions();
	}, [previousAssignedAcctions]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Spin spinning={isLoadingGetActions || isLoadingPutActions}>
			{/** ************************* */}
			{/** BODY */}
			<Drawer
				width='650'
				placement='right'
				onClose={onClickClose}
				closable={false}
				open={openDrawerAssignActions}
			>
				{/** ------------------------------------------------------------- */}
				{/** ADAPTER INFORMATION */}
				<div>
					<Divider orientation='left' className='generalStyles__drawerDivider'>
						<h5>
							<FontAwesomeIcon
								icon={faPlugCircleCheck}
								className='generalStyles__info generalStyles__mrFix'
							/>
							{t("adapter.adapter")}
						</h5>
					</Divider>
				</div>
				{/** ------------------------------------------------------------- */}
				{/** ADAPTER  */}
				<div className={`drawer__box__${theme}`}>
					{/* ID */}
					<DrawerRowItem
						title='ID'
						value={adapter && adapter.id ? adapter.id : "--"}
					/>
					{/* NAME */}
					<DrawerRowItem
						title={t("general.name")}
						value={adapter && adapter.name ? adapter.name : "--"}
					/>
				</div>
				{/** ------------------------------------------------------------- */}
				{/** ADAPTER ASSIGN ACTIONS */}
				<div>
					<Divider orientation='left' className='generalStyles__drawerDivider'>
						<h5>
							<FontAwesomeIcon
								icon={faGear}
								className='generalStyles__info generalStyles__mrFix'
							/>
							{t("adapter.assignActions")}
						</h5>
					</Divider>
				</div>
				<div className={`drawer__box__${theme}`}>
					{actions.map((action: ActionAdapterInterface) => (
						<div className='generalStyles__flex mt-2' key={action.id}>
							<div>
								<Checkbox
									className='mt-1'
									checked={action.isActive}
									onChange={(e) => {
										onChangeCheckbox(e, action.id);
									}}
								/>
							</div>
							<div>
								<Tag
									color='cyan'
									style={{
										width: "50px",
										textAlign: "center",
										marginLeft: "15px",
									}}
								>
									{action.cmd}
								</Tag>
							</div>
							<div>
								<span>{action.label}</span>
							</div>
						</div>
					))}
				</div>
				{/** ---------------------------------------------------- */}
				{/** COMPONENT CLOSE SAVE BUTTON */}
				<DrawerCloseSaveButton
					onClickClose={onClickClose}
					onClickSave={onClickSave}
					disableSaveButton={false}
				/>
				{/** ---------------------------------------------------- */}
				{/** GLOBAL ALERT */}
				<GlobalAlert2
					isError={isErrorPutActions}
					isSuccess={isSuccessPutActions}
					requestType='PUT'
					error={errorPutActions}
					name='Adapter'
				/>
			</Drawer>
		</Spin>
	);
}

export default AdaptersAssignActions;
