import React from "react";
// ANT DESIGN COMPONENTS
// DEVICE DETECT
import { isMobile } from "react-device-detect";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { faPlugCircleCheck } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import DrawerRowItem from "./DrawerRowItem";
import DrawerRowDivider from "./DrawerRowDivider";

import { RootState } from "../../../app/store";

function DrawerKeyInformation({ data }: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const value = JSON.parse(data.value);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div style={{ overflowX: "auto" }}>
			<br />
			{/** ************************************************* */}
			{/** COMPONENT DRAWER DIVIDER */}
			<DrawerRowDivider
				icon={faPlugCircleCheck}
				title={t("key.keyInformation")}
			/>
			{/** ------------------------------------------------------------- */}
			{/** KEY  */}
			<div className={`drawer__box__${theme}`}>
				{/* ID */}
				<DrawerRowItem title='ID' value={data && data.id ? data.id : "--"} />
				{/* VALUE */}
				<div className='generalStyles__flex drawer__container'>
					<div>
						<p className={isMobile ? "drawer__titleMobile" : "drawer__title"}>
							{t("key.value")}
						</p>
					</div>
					<div className='drawer__textContainer' />
				</div>
				<div className='generalStyles__flex drawer__container'>
					<div>
						<pre className='drawer__text'>
							{data && data.value !== ""
								? JSON.stringify(value, null, 2)
								: "--"}
						</pre>
					</div>
				</div>
			</div>
		</div>
	);
}

export default DrawerKeyInformation;
