import React from "react";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
// import KeysTable from "./KeysTable";
// import KeysDeleteModal from "./KeysDeleteModal";
// AUTHORIZATION
import validatePermission from "../../../../utils/validatePermissions";
import UnauthorizedPage from "../../../unauthorized/UnauthorizedPage";
// INTERFACES
import { RootState } from "../../../../app/store";
import KeysHeader from "./KeysHeader";
import KeysTable from "./KeysTable";
import KeysCreateEditForm from "./KeysCreateEditForm";
import KeysDeleteModal from "./KeysDeleteModal";
import KeysDrawer from "./KeysDrawer";
import KeysImportForm from "./KeysImportForm";

const KeysPage = () => {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const { permissions } = useSelector((state: any) => state.user);
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{validatePermission("permission-not-defined", permissions) ? (
				<div className={`layout__contentPage__${theme}`}>
					<KeysHeader />
					<KeysTable />
					<KeysCreateEditForm />
					<KeysImportForm />
					<KeysDeleteModal />
					<KeysDrawer />
				</div>
			) : (
				<UnauthorizedPage />
			)}
		</div>
	);
};

export default KeysPage;
