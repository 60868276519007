// REDUX
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
// REDUCERS / SLICES
import { keyAPI } from "../redux/keys/keysAPI";
import homeReducer from "../redux/home/homeSlice";
import loginReducer from "../redux/login/loginSlice";
import organizationReducer from "../redux/organizations/organizationSlice";
import userReducer from "../redux/users/userSlice";
import roleReduer from "../redux/roles/roleSlice";
import configurationReducer from "../redux/configuration/configurationSlice";
import serverReducer from "../redux/servers/serverSlice";
import bankReducer from "../redux/banks/bankSlice";
import liftReducer from "../redux/lifts/liftSlice";
import configurationFileReducer from "../redux/configurationFiles/configurationFilesSlice";
import monitoringReducer from "../redux/monitoring/monitoringSlice";
import toolReducer from "../redux/tools/toolsSlice";
import authenticationReducer from "../redux/authentication/authenticationSlice";
import reportReducer from "../redux/reports/reportsSlice";
import adapterReducer from "../redux/adapters/adapterSlice";
import channelReducer from "../redux/channels/channelSlice";
import ActionReducer from "../redux/actions/actionsSlice";
import MessageTypeReducer from "../redux/messageType/messagesTypeSlice";
import MessageReducer from "../redux/messages/messagesSlice";
import KeyReducer from "../redux/keys/keysSlice";
import GroupReducer from "../redux/groups/groupsSlice";
import NotificationReducer from "../redux/notifications/notificationSlice";
// API
import { loginAPI } from "../redux/login/loginAPI";
import { countriesStatesCitiesAPI } from "../redux/countriesStatesCitiesAPI";
import { organizationAPI } from "../redux/organizations/organizationAPI";
import { userAPI } from "../redux/users/userAPI";
import { roleAPI } from "../redux/roles/roleAPI";
import { serverAPI } from "../redux/servers/serverAPI";
import { bankAPI } from "../redux/banks/bankAPI";
import { liftAPI } from "../redux/lifts/liftAPI";
import { configurationFileAPI } from "../redux/configurationFiles/configurationFilesAPI";
import { monitoringAPI } from "../redux/monitoring/monitoringAPI";
import { toolAPI } from "../redux/tools/toolsAPI";
import { reportAPI } from "../redux/reports/reportsAPI";
import { chartAPI } from "../redux/reports/chartsAPI";
import { adapterAPI } from "../redux/adapters/adapterAPI";
import { channelAPI } from "../redux/channels/channelAPI";
import { actionAPI } from "../redux/actions/actionsAPI";
import { messageTypeAPI } from "../redux/messageType/messagesTypeAPI";
import { messageAPI } from "../redux/messages/messagesAPI";
import { groupAPI } from "../redux/groups/groupsAPI";
import { notificationAPI } from "../redux/notifications/notificationAPI";

export const store = configureStore({
	reducer: {
		home: homeReducer,
		login: loginReducer,
		organization: organizationReducer,
		user: userReducer,
		role: roleReduer,
		configuration: configurationReducer,
		server: serverReducer,
		bank: bankReducer,
		lift: liftReducer,
		configurationFile: configurationFileReducer,
		monitoring: monitoringReducer,
		tool: toolReducer,
		authentication: authenticationReducer,
		report: reportReducer,
		adapter: adapterReducer,
		key: KeyReducer,
		channel: channelReducer,
		action: ActionReducer,
		messageType: MessageTypeReducer,
		message: MessageReducer,
		group: GroupReducer,
		notification: NotificationReducer,
		// API REDUCERS
		[loginAPI.reducerPath]: loginAPI.reducer,
		[organizationAPI.reducerPath]: organizationAPI.reducer,
		[userAPI.reducerPath]: userAPI.reducer,
		[countriesStatesCitiesAPI.reducerPath]: countriesStatesCitiesAPI.reducer,
		[roleAPI.reducerPath]: roleAPI.reducer,
		[serverAPI.reducerPath]: serverAPI.reducer,
		[bankAPI.reducerPath]: bankAPI.reducer,
		[liftAPI.reducerPath]: liftAPI.reducer,
		[configurationFileAPI.reducerPath]: configurationFileAPI.reducer,
		[monitoringAPI.reducerPath]: monitoringAPI.reducer,
		[toolAPI.reducerPath]: toolAPI.reducer,
		[reportAPI.reducerPath]: reportAPI.reducer,
		[chartAPI.reducerPath]: chartAPI.reducer,
		[adapterAPI.reducerPath]: adapterAPI.reducer,
		[channelAPI.reducerPath]: channelAPI.reducer,
		[actionAPI.reducerPath]: actionAPI.reducer,
		[messageTypeAPI.reducerPath]: messageTypeAPI.reducer,
		[messageAPI.reducerPath]: messageAPI.reducer,
		[keyAPI.reducerPath]: keyAPI.reducer,
		[groupAPI.reducerPath]: groupAPI.reducer,
		[notificationAPI.reducerPath]: notificationAPI.reducer,
	},
	// Adding the api middleware enables caching, invalidation, polling
	// and other useful features off rtk-query
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({ serializableCheck: false }).concat(
			organizationAPI.middleware,
			userAPI.middleware,
			roleAPI.middleware,
			serverAPI.middleware,
			bankAPI.middleware,
			liftAPI.middleware,
			configurationFileAPI.middleware,
			monitoringAPI.middleware,
			reportAPI.middleware,
			chartAPI.middleware,
			adapterAPI.middleware,
			channelAPI.middleware,
			actionAPI.middleware,
			messageTypeAPI.middleware,
			messageAPI.middleware,
			keyAPI.middleware,
			groupAPI.middleware,
			notificationAPI.middleware
		),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = store.getState
// eslint-disable-next-line no-undef
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
