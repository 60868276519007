import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
// ANT DESIGN COMPONENTS
import { Table, Empty, Tag, Spin, Tooltip, Button } from "antd";
// HTML CANVAS
import html2canvas from "html2canvas";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// TIMEZONE
import moment from "moment-timezone";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	updateOpenDrawerRows,
	updateReportRow,
	updateFilters,
} from "../../redux/reports/reportsSlice";
// AUTHORIZATION
import validatePermission from "../../utils/validatePermissions";
import GETJwtToken from "../../redux/authentication/authentication";
// UTILS
import { getTableRowClass } from "../../utils/utils";
// COMPONENTS
import TableNoDataBox from "../components/table/TableNoDataBox";
import ReportsDrawer from "./drawer/ReportsDrawer";
import ReportsAlertDownloadModal from "./ReportsAlertDownloadModal";
// INTERFACES
import {
	ReportsTableInterface,
	ReportsListOfColumnsInterface,
	ReportSliceFiltersInterface,
} from "../../interfaces/Reports.interface";
import { RootState } from "../../app/store";

function ReportsTable({
	response,
	triggerGetReport,
	isLoading,
	isError,
	error,
	displayExpandibleRowTable,
	groupColumns,
	generateTableRequestBody,
	csvUrl,
}: ReportsTableInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const dispatch = useDispatch();
	const CUSTOM_COLUM_NAMES: any = {
		up_time_target: "AVAILABILITY (%)",
		unavailability: "UNAVAILABILITY (%)",
		start_timestamp: "START TIME",
		end_timestamp: "END TIME",
		lift_name: "CAR",
		bank_name: "BANK",
		floor_name: "FLOOR",
	};
	const { Column, ColumnGroup } = Table;
	const tableRef = useRef(null);

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [data, setData] = useState<any>([]);
	const [downloadReport, setDownloadReport] = useState<boolean>(false);
	const [total, setTotal] = useState(0);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: RootState) => state.user);
	const { reportRefreshTable, filters, openPdfViewer, chartReportTheme } =
		useSelector((state: RootState) => state.report);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	/** Take all the values that comes from report API call and create
  a list with all the available columns */
	const buildListOfColumnsArray = () => {
		const newArr: ReportsListOfColumnsInterface[] = [];
		Object.keys(data[0]).forEach((key: any) => {
			const isArrayValidation = Array.isArray(data[0][key]);
			if (!(typeof data[0][key] === "object" || isArrayValidation)) {
				newArr.push({
					label: key,
					value: key,
				});
			}
			return true;
		});
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		copy.tableListColumns = newArr;
		copy.tableSelectedColumns = filters.defaultColumns;
		dispatch(updateFilters(copy));
	};

	const dinamicColumnTitle = (title: string) => {
		if (CUSTOM_COLUM_NAMES[title]) {
			return CUSTOM_COLUM_NAMES[title].toUpperCase();
		}
		return title.toUpperCase().replaceAll("_", " ");
	};

	const getData = async () => {
		const token = await GETJwtToken();
		const body = generateTableRequestBody({ downloadReport: "false" });
		triggerGetReport({
			token,
			body,
		});
	};

	const buildAndDownloadReport = async () => {
		const token: string = await GETJwtToken();
		setTimeout(() => {
			setDownloadReport(false);
		}, 1000);
		// Generate body
		const body = generateTableRequestBody({ downloadReport: "true" });
		// Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
		// eslint-disable-next-line no-return-await
		return await axios
			.post(csvUrl, body, {
				responseType: "blob",
				headers: {
					Authorization: token,
					"Content-type": "application/json",
				},
			})
			.then((res: any) => {
				const data1 = new Blob([res?.data], {
					type: "application / vnd. MS Excel",
				});
				const csvURL = window.URL.createObjectURL(data1);
				const tempLink = document.createElement("a");
				tempLink.href = csvURL;
				tempLink.setAttribute(
					"download",
					`Liftnet ${t("general.report")} ${moment()
						.tz(filters.timezoneCode || moment.tz.guess())
						.format("MMMM Do YYYY, h:mm:ss a")}.csv`
				);
				tempLink.click();
			});
	};

	const refreshTable = async () => {
		getData();
	};

	const formatDateColumn = (value: string) =>
		moment(value)
			.tz(
				filters.timezoneCode !== "" ? filters.timezoneCode : moment.tz.guess()
			)
			.format("MMMM Do YYYY, h:mm:ss a");

	const buildDataObject = (newData: any) => {
		const newArr: any = [];
		const formatDates = [
			"created_at",
			"synced_at",
			"start_timestamp",
			"end_timestamp",
			"timestamp",
			"out_of_service_timestamp",
		];
		newData.map((record: any) => {
			const newObject = { ...record };
			// Format dates
			formatDates.map((d: string) => {
				if (record[d]) {
					if (record[d] !== "0001-01-01T00:00:00Z" && record[d] !== "-") {
						newObject[d] = formatDateColumn(record[d]);
					} else {
						newObject[d] = "--";
					}
				}
				return true;
			});
			if (record.msg) {
				Object.keys(record.msg).forEach((key: any) => {
					newObject[key] = record.msg[key];
					return true;
				});
			}
			newArr.push(newObject);
			return true;
		});
		setData(newArr);
	};

	const onCellNameClick = (record: any) => {
		if (record) {
			dispatch(updateOpenDrawerRows(true));
			dispatch(updateReportRow(record));
		}
	};

	const captureChartAsImage = () => {
		if (tableRef.current) {
			html2canvas(tableRef.current).then((canvas) => {
				const imgData: any = canvas.toDataURL("image/png");
				const copy: ReportSliceFiltersInterface = structuredClone(filters);
				copy.urlChartImage = imgData;
				dispatch(updateFilters(copy));
			});
		}
	};

	const customExpandIcon = ({ expanded, onExpand, record }: any) => (
		<Tooltip title={t("general.interruptions")}>
			<Button
				onClick={(e) => onExpand(record, e)}
				style={{ cursor: "pointer" }}
				shape='circle'
			>
				{expanded ? "-" : "+"}
			</Button>
		</Tooltip>
	);

	const expandedRowRender = (record: any) => (
		<Table
			className='customTable'
			dataSource={record.interruptions}
			rowKey={(record2: any) => record2.id}
			size='small'
			pagination={false}
		>
			{/** DATE -- */}
			<Column
				title={t("general.date")}
				dataIndex='date'
				key='date'
				render={(text) => <div className=''>{text || "--"}</div>}
			/>
			{/** DURATION -- */}
			<Column
				title={t("general.duration")}
				dataIndex='duration'
				key='duration'
				render={(text) => <div className=''>{text || "--"}</div>}
			/>
			{/** FLOOR NAME -- */}
			<Column
				title={t("general.floorName")}
				dataIndex='floor_name'
				key='floor_name'
				render={(text) => <div className=''>{text || "--"}</div>}
			/>
			{/** MESSAGE -- */}
			<Column
				title={t("general.message")}
				dataIndex='message'
				key='message'
				render={(text) => <div className=''>{text || "--"}</div>}
			/>
		</Table>
	);

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (data && data.length > 0) {
			buildListOfColumnsArray();
		}
	}, [data]);

	useEffect(() => {
		if (response) {
			if (response.data) {
				buildDataObject(response.data);
			} else if (response.results) {
				buildDataObject(response.results);
			} else {
				buildDataObject([]);
			}
			// Save total
			if (response.total) {
				setTotal(response.total);
			}
		}
	}, [response]);

	useEffect(() => {
		if (isError) {
			setData([]);
		}
	}, [isError]);

	useEffect(() => {
		if (reportRefreshTable) {
			refreshTable();
		}
	}, [reportRefreshTable]);

	useEffect(() => {
		if (downloadReport) {
			buildAndDownloadReport();
		}
	}, [downloadReport]);

	useEffect(() => {
		if (openPdfViewer) {
			captureChartAsImage();
		}
	}, [openPdfViewer]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Spin spinning={openPdfViewer}>
			<div
				style={{ opacity: openPdfViewer ? 0 : 1 }}
				className={`${
					chartReportTheme === "light" ? "custom-light-table" : ""
				} `}
			>
				{validatePermission("permission-not-defined", permissions) ? (
					<>
						{/** ******************************************* */}
						{/** TABLE */}
						<Table
							locale={{
								emptyText: (
									<TableNoDataBox
										isError={isError}
										errorMessage={
											error && error.data && error.data.message
												? error.data.message
												: "Error"
										}
										noItemsFound='No data found'
									/>
								),
							}}
							loading={isLoading}
							ref={tableRef}
							rowClassName={(record, index) =>
								getTableRowClass(index, chartReportTheme)
							}
							dataSource={data}
							size='small'
							className='mt-3'
							pagination={{
								showSizeChanger: true,
								pageSizeOptions: ["10", "20", "50", "100"],
							}}
							scroll={{ x: 900 }}
							footer={() => (
								<div className='generalStyles__flexEnd'>
									<Tag
										style={{
											color: chartReportTheme === "light" ? "black" : "",
										}}
									>
										{total} Records
									</Tag>
								</div>
							)}
							expandable={
								displayExpandibleRowTable
									? {
											// eslint-disable-next-line object-shorthand
											expandedRowRender: expandedRowRender,
											// eslint-disable-next-line object-shorthand
											expandIcon: customExpandIcon,
									  }
									: {}
							}
						>
							{/** DINAMIC COLUMNS */}
							{filters.tableSelectedColumns.map(
								(column: string, indexColumn: number) => (
									<Column
										onCell={(record) => ({
											onClick: () => {
												onCellNameClick(record);
											},
										})}
										title={dinamicColumnTitle(column)}
										dataIndex={column}
										key={indexColumn}
										sorter={(a: any, b: any) =>
											a[column].localeCompare(b[column])
										}
										render={(text: string) => <div className=''>{text}</div>}
									/>
								)
							)}
							{/** DINAMIC GROUP COLUMNS */}
							{groupColumns && groupColumns.length > 0 && (
								<>
									{groupColumns.map((column: any, indexColumn: number) => (
										<ColumnGroup title={column.name}>
											{column && column.data && (
												<>
													{column.data.map((item: string) => (
														<Column
															title={item.substring(4)}
															dataIndex={item}
															key={indexColumn}
															align='center'
														/>
													))}
												</>
											)}
										</ColumnGroup>
									))}
								</>
							)}
						</Table>
					</>
				) : (
					<>
						<div className='generalStyles__noAccessToListTable'>
							<Empty
								description={t(
									"organizationManagement.listNotAvailableOrganizations"
								)}
								image={Empty.PRESENTED_IMAGE_SIMPLE}
							/>
						</div>
					</>
				)}
				{/** ******************************************* */}
				{/** REPORT DRAWER BY ROW */}
				<ReportsDrawer />
				{/** ******************************************* */}
				{/** DOWNLOAD REPORT */}
				<ReportsAlertDownloadModal setDownloadReport={setDownloadReport} />
			</div>
		</Spin>
	);
}

export default ReportsTable;
