import React from "react";
// ANT DESIGN COMPONENTS
import { InputNumber } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import { updateFilters } from "../../../redux/reports/reportsSlice";
// INTERFACES
import { RootState } from "../../../app/store";
import { ReportSliceFiltersInterface } from "../../../interfaces/Reports.interface";

function ReportsFilterLimitNumber() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { filters } = useSelector((state: RootState) => state.report);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onChangeLimitNumber = (newValue: number) => {
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		copy.limitNumber = newValue;
		dispatch(updateFilters(copy));
	};
	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<InputNumber
				placeholder={t("general.limit")}
				min={1}
				max={999}
				defaultValue={3}
				size='small'
				value={filters.limitNumber}
				addonBefore={t("general.limit")}
				onChange={(e: any) => {
					onChangeLimitNumber(e);
				}}
			/>
		</div>
	);
}

export default ReportsFilterLimitNumber;
