import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Modal, Button, Spin } from "antd";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
	updateOpenPdfViewer,
	updateChartReportTheme,
} from "../../redux/reports/reportsSlice";
// INTERFACES
import { RootState } from "../../app/store";
// COMPONENTES
import PDFGenerator from "./PDF/PDFGenerator";

function ReportsPDFViewerModal() {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [showIsLoading, setShowIsLoading] = useState(false);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { openPdfViewer } = useSelector((state: RootState) => state.report);
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	const handleCloseModal = async () => {
		dispatch(updateChartReportTheme(theme));
		setShowIsLoading(true);
		setTimeout(() => {
			setShowIsLoading(false);
			dispatch(updateOpenPdfViewer(false));
		}, 500);
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (openPdfViewer) {
			setShowIsLoading(true);
			setTimeout(() => {
				setShowIsLoading(false);
			}, 2000);
		}
	}, [openPdfViewer]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Modal
			onOk={handleCloseModal}
			onCancel={handleCloseModal}
			closable
			open={openPdfViewer}
			width='90%'
			footer={[
				<Button key='back' onClick={handleCloseModal}>
					Close
				</Button>,
			]}
		>
			<Spin spinning={showIsLoading}>
				<div style={{ opacity: showIsLoading ? 0 : 1 }}>
					<PDFGenerator />
				</div>
			</Spin>
		</Modal>
	);
}

export default ReportsPDFViewerModal;
