import React from "react";
// ANT DESIGN COMPONENTS
import { Select } from "antd";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateFilters } from "../../../redux/reports/reportsSlice";
// INTERFACES
import { RootState } from "../../../app/store";
import { ReportSliceFiltersInterface } from "../../../interfaces/Reports.interface";

function ReportsFilterChartType() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { Option } = Select;
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { filters } = useSelector((state: RootState) => state.report);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onChangeCharType = (newValue: string) => {
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		copy.chartType = newValue;
		copy.chartMirror = false;
		dispatch(updateFilters(copy));
	};

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<Select
				showSearch
				optionFilterProp='children'
				className='generalStyles__width100'
				allowClear={false}
				value={filters.chartType}
				onChange={onChangeCharType}
				size='small'
				listHeight={500}
			>
				{[
					{ id: "bar_stack_vertical", name: "Bar Stack Vertical" },
					{ id: "bar_vertical", name: "Bar Vertical" },
					{ id: "bar_stack_horizontal", name: "Bar Stack Horizontal" },
					{ id: "bar_horizontal", name: "Bar Horizontal" },
					{ id: "line", name: "Line Linear" },
					{ id: "line_natural", name: "Line Natural" },
					{ id: "step_before", name: "Step Before" },
					{ id: "step_after", name: "Step After" },
					// { id: "pie", name: "Pie by Day" },
					// { id: "pie_global", name: "Pie by Month" },
				].map((item: any) => (
					<Option key={item.id} value={item.id}>
						{item.name}
					</Option>
				))}
			</Select>
		</div>
	);
}

export default ReportsFilterChartType;
