/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// INTERFACES
import { MessageInterface } from "../../interfaces/Message.interface";

export interface MessageState {
    message: MessageInterface;
    openDrawer: boolean;
    openForm: boolean;
    showTable: boolean;
    refreshTable: boolean;
    formAction: string;
    openModalDelete: boolean;
}

const initialState: MessageState = {
    message: {
        id: "",
        description: "",
        message_type_id: "",
        position: false,
        short_message: ""
    },
    openDrawer: false,
    openForm: false,
    showTable: false,
    refreshTable: false,
    formAction: "CREATE",
    openModalDelete: false,
};

export const messageSlice = createSlice({
    name: "messageType",
    initialState,
    reducers: {
        updateMessage: (state, action: PayloadAction<MessageInterface>) => {
            state.message = action.payload;
        },
        updateOpenDrawer: (state, action: PayloadAction<boolean>) => {
            state.openDrawer = action.payload;
        },
        updateOpenForm: (state, action: PayloadAction<boolean>) => {
            state.openForm = action.payload;
        },
        updateShowTable: (state, action: PayloadAction<boolean>) => {
            state.showTable = action.payload;
        },
        updateRefreshTable: (state, action: PayloadAction<boolean>) => {
            state.refreshTable = action.payload;
        },
        updateFormAction: (state, action: PayloadAction<string>) => {
            state.formAction = action.payload;
        },
        updateOpenModalDelete: (state, action: PayloadAction<boolean>) => {
            state.openModalDelete = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    updateMessage,
    updateOpenDrawer,
    updateOpenForm,
    updateShowTable,
    updateRefreshTable,
    updateFormAction,
    updateOpenModalDelete,
} = messageSlice.actions;

export default messageSlice.reducer;
