/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// INTERFACES
import {
	ReportSliceFiltersInterface,
	ReportsSliceViews,
} from "../../interfaces/Reports.interface";

export interface ReportState {
	openModalDownloadReport: boolean;
	openDrawerFilters: boolean;
	openDrawerRows: boolean;
	openPdfViewer: boolean;
	reportRow: any;
	reportRefreshTable: boolean;
	chartReportTheme: string;
	pdfReportOrientation: string;
	// ********************************
	filters: ReportSliceFiltersInterface;
	views: ReportsSliceViews;
}

const initialState: ReportState = {
	openModalDownloadReport: false,
	openDrawerFilters: false,
	openDrawerRows: false,
	openPdfViewer: false,
	reportRow: {},
	reportRefreshTable: false,
	chartReportTheme: "dark",
	pdfReportOrientation: "landscape",
	// ********************************
	filters: {
		reportType: "REPORT",
		levelTypeName: "",
		displaySelectTypeLevel: false,
		liftType: "ALL",
		startDate: "",
		endDate: "",
		dateType: "MONTH",
		organizationId: "",
		organizationName: "",
		serverName: "",
		serverId: "",
		liftName: "",
		liftId: "",
		bankName: "",
		bankId: "",
		banks: [],
		displayAdvanceFiltersExclusions: false,
		exclusionTimeList: [],
		exclusionDateList: [],
		exclusionTime: false,
		exclusionDate: false,
		exclusionSaturdays: false,
		exclusionSundays: false,
		exclusionHolidays: false,
		timezoneCode: "",
		timezoneLabel: "",
		timezoneUTC: "",
		timezoneName: "",
		timezoneLocal: false,
		tableListColumns: [],
		tableSelectedColumns: [],
		useTablePagination: false,
		defaultColumns: [],
		displayAdvanceFiltersOffsets: false,
		disabledOffsets: false,
		defaultOffsets: [],
		offsetsList: [],
		infoDescriptionText: "",
		displayDownloadCSVReport: false,
		displayDownloadPDFReport: true,
		reportTitle: "",
		displayRecentWeeksPicket: false,
		weeksNumber: 5,
		limitNumber: 10,
		sortAsc: true,
		getFirstDefaultValue: true,
		carsList: [],
		selectedCarsList: [],
		chartType: "bar_stack_vertical",
		dateTypeOptions: [
			{ id: "TODAY", name: "Day" },
			{ id: "WEEK", name: "Week" },
			{ id: "MONTH", name: "Month" },
			{ id: "QUARTER", name: "Quarter" },
			{ id: "YEAR", name: "Year" },
		],
		dateDaysLimit: 1,
		dateWeeksLimit: 1,
		dateMonthsLimit: 1,
		dateQuarterLimit: 1,
		dateYearsLimit: 1,
		dateTimeSeconds: 0,
		displayAdvanceFilterCharts: false,
		displayAdvanceFiltersTable: false,
		displayDateByRange: true,
		displayMinimumActivation: false,
		displayDate: true,
		displaySelectLiftType: true,
		displayInputLimitItems: false,
		displayChartMirror: false,
		chartMirror: false,
		urlChartImage: "",
	},
	views: {
		displayTimeRanges: false,
		displayDateRanges: false,
	},
};

export const reportSlice = createSlice({
	name: "report",
	initialState,
	reducers: {
		updateOpenAlertDownloadReport: (state, action: PayloadAction<boolean>) => {
			state.openModalDownloadReport = action.payload;
		},
		updateOpenDrawerFilters: (state, action: PayloadAction<boolean>) => {
			state.openDrawerFilters = action.payload;
		},
		updateOpenPdfViewer: (state, action: PayloadAction<boolean>) => {
			state.openPdfViewer = action.payload;
		},
		updateReportRow: (state, action: PayloadAction<any>) => {
			state.reportRow = action.payload;
		},
		updateOpenDrawerRows: (state, action: PayloadAction<boolean>) => {
			state.openDrawerRows = action.payload;
		},
		updateChartReportTheme: (state, action: PayloadAction<string>) => {
			state.chartReportTheme = action.payload;
		},
		updatePdfReportOrientation: (state, action: PayloadAction<string>) => {
			state.pdfReportOrientation = action.payload;
		},
		updateReportRefreshTable: (state, action: PayloadAction<boolean>) => {
			state.reportRefreshTable = action.payload;
		},
		updateFilters: (
			state,
			action: PayloadAction<ReportSliceFiltersInterface>
		) => {
			state.filters = action.payload;
		},
		updateViews: (state, action: PayloadAction<ReportsSliceViews>) => {
			state.views = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	updateOpenAlertDownloadReport,
	updateOpenDrawerFilters,
	updateOpenDrawerRows,
	updateOpenPdfViewer,
	updateReportRow,
	updateReportRefreshTable,
	updateFilters,
	updateViews,
	updateChartReportTheme,
	updatePdfReportOrientation,
} = reportSlice.actions;

export default reportSlice.reducer;
