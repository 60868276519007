import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Spin, Divider, Drawer, Button } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEthernet } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateOpenModalCreateNewKey } from "../../../../redux/keys/keysSlice";
import {
	usePostKeyMutation,
	usePutKeyMutation,
} from "../../../../redux/keys/keysAPI";
// COMPONENTS
import FormInputText from "../../../components/form/FormInputText";
import GlobalAlert2 from "../../../home/GlobalAlert2";
// AUTHORIZATION
import GETJwtToken from "../../../../redux/authentication/authentication";
// INTERFACES
import { KeyInterface } from "../../../../interfaces/Key.interface";
import { RootState } from "../../../../app/store";
import AssignMessage from "../../../tools/messages/AssignMessage";

function KeysCreateEditForm() {
	const { keysAdapter } = useSelector((state: RootState) => state.adapter);
	type adapterInterface = { [key: string]: string };
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const dispatch = useDispatch();
	const BLANK_KEY: KeyInterface = {
		adapter_id: "",
		message_id: "",
		value: "",
		identifier: {},
	};

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [key, setKey] = useState<KeyInterface>(BLANK_KEY);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [messageId, setMessageId] = useState<string>("");
	const [jsonStringValue, setJsonStringValue] = useState<string>("");
	const [isValidValue, setIsValidValue] = useState<boolean>(false);
	const [valueObj, setValueObj] = useState<adapterInterface>({});

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);
	const {
		openModalCreateNewKey,
		formCreateEdit,
		key: selectedKey,
	} = useSelector((state: RootState) => state.key);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [
		postKey,
		{
			isSuccess: isSuccessPostKey,
			isLoading: isLoadingPostKey,
			isError: isErrorPostKey,
			error: errorPostKey,
			reset: resetPostKey,
		},
	] = usePostKeyMutation();

	const [
		putKey,
		{
			isSuccess: isSuccessPutKey,
			isLoading: isLoadingPutKey,
			isError: isErrorPutKey,
			error: errorPutKey,
			reset: resetPutKey,
		},
	] = usePutKeyMutation();
	// ************************************************ */
	// FUNCTIONS ************************************** */
	const isValidJSON = (str: string) => {
		try {
			JSON.parse(str);
			return true;
		} catch (e) {
			return false;
		}
	};

	const showIsLoading = () => {
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
		}, 1000);
	};

	const onClickClose = () => {
		dispatch(updateOpenModalCreateNewKey(false));
		resetPostKey();
		setMessageId("");
		setJsonStringValue("");
		setValueObj({});
		setKey(BLANK_KEY);
		resetPutKey();
	};

	const postNewKey = async () => {
		const token = await GETJwtToken();
		const BODY: any = {
			adapter_id: key.adapter_id,
			message_id: key.message_id,
			value: key.value,
		};

		postKey({
			token,
			body: BODY,
		});
	};

	const putOldKey = async () => {
		const token = await GETJwtToken();
		const copy = structuredClone(key);
		putKey({ token, body: copy, id: key.id });
	};

	const onClickSave = async () => {
		if (formCreateEdit === "create") {
			postNewKey();
		} else if (formCreateEdit === "edit") {
			putOldKey();
		}
	};

	const fillEditFormData = () => {
		setKey(selectedKey);
		setMessageId(selectedKey.message_id);
		setJsonStringValue(selectedKey.value);
		try {
			if (typeof selectedKey.value === "object") {
				setValueObj(selectedKey.value);
			} else {
				setValueObj(JSON.parse(selectedKey.value));
			}
		} catch (_) {
			setValueObj({});
		}
	};

	const validValue = (currentKey: KeyInterface): boolean => {
		if (currentKey.value === "") {
			return false;
		}
		if (!keysAdapter.interface) {
			return false;
		}

		let jObject: Object;
		try {
			if (typeof JSON.parse(currentKey.value) === "string") {
				jObject = JSON.parse(JSON.parse(currentKey.value));
			} else {
				jObject = JSON.parse(currentKey.value);
			}
		} catch (_) {
			return false;
		}

		const jObjectPropNames = Object.getOwnPropertyNames(jObject);
		const adapterInterfacePropNames = Object.getOwnPropertyNames(
			keysAdapter.interface
		);
		if (!jObjectPropNames || !adapterInterfacePropNames) {
			return false;
		}

		if (jObjectPropNames.length !== adapterInterfacePropNames.length) {
			return false;
		}

		let hasAll = true;
		Object.keys(keysAdapter.interface).forEach((keyName) => {
			if (!Object.hasOwn(jObject, keyName)) {
				hasAll = false;
			}
		});

		return hasAll;
	};

	const onChangeFormValue = (newValue: string, variableName: string) => {
		const copy = structuredClone(valueObj);
		copy[variableName] = newValue;
		setValueObj(copy);
	};
	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (formCreateEdit === "edit") {
			fillEditFormData();
		} else {
			resetPostKey();
			setMessageId("");
			setJsonStringValue("");
			setKey(BLANK_KEY);
		}
		showIsLoading();
	}, [openModalCreateNewKey]);

	useEffect(() => {
		onClickClose();
	}, [isSuccessPostKey, isErrorPostKey, isSuccessPutKey, isErrorPutKey]);

	useEffect(() => {
		const copy: KeyInterface = structuredClone(key);
		if (isValidJSON(jsonStringValue)) {
			copy.value = jsonStringValue;
		} else {
			copy.value = "";
		}
		setKey(copy);
		setIsValidValue(validValue(copy));
	}, [jsonStringValue]);

	useEffect(() => {
		const copy: KeyInterface = structuredClone(key);
		copy.adapter_id = keysAdapter.id || "";
		copy.message_id = messageId;
		setKey(copy);
		setIsValidValue(validValue(copy));
	}, [messageId]);

	useEffect(() => {
		try {
			setJsonStringValue(JSON.stringify(valueObj));
		} catch (_) {
			setJsonStringValue("");
		}
	}, [valueObj]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Drawer
			width='35%'
			placement='right'
			onClose={onClickClose}
			closable={false}
			open={openModalCreateNewKey}
		>
			<Spin spinning={isLoadingPostKey || isLoading || isLoadingPutKey}>
				<div>
					{/** ------------------------------------------------------------- */}
					{/** GLOBAL DATA  */}
					<div>
						<Divider
							orientation='left'
							className='generalStyles__drawerDivider'
						>
							<h5>
								<FontAwesomeIcon
									icon={faEthernet}
									className='generalStyles__info generalStyles__mrFix'
								/>
								{formCreateEdit === "edit" ? (
									<> {t("key.editKey")}</>
								) : (
									<> {t("key.createNewKey")}</>
								)}
							</h5>
						</Divider>
					</div>
					{/** ------------------------------------------------------------- */}
					{/** KEY FORM  */}
					<div className='container'>
						<div className={`drawer__box__${theme} row`}>
							{/** KEY VALUE  */}
							{
								// /** COMPONENTS FORM - INPUT VERSION */
								Object.entries(keysAdapter.interface || {}).map(([item]) => (
									<FormInputText
										inputValue={valueObj[item]}
										inputLabelName={
											item.charAt(0).toUpperCase() + item.slice(1)
										}
										inputOnChange={onChangeFormValue}
										inputVariableName={item}
										key={item}
										isRequired
									/>
								))
							}
						</div>
					</div>
					<br />
					{/** ------------------------------------------------------------- */}
					{/** MESSAGE  */}
					<AssignMessage messageId={messageId} setMessageId={setMessageId} />
				</div>
				{/** ---------------------------------------------------- */}
				{/** CLOSE AND SAVE BUTTON */}
				<div className='mt-4'>
					<div>
						<Button
							type='default'
							onClick={onClickClose}
							className='buttonStyle__3'
						>
							{t("edgeManagement.close")}
						</Button>
						<Button
							type='primary'
							className='buttonStyle__3'
							onClick={onClickSave}
							disabled={
								key.adapter_id === "" || key.message_id === "" || !isValidValue
							}
						>
							{t("general.save")}
						</Button>
					</div>
				</div>
			</Spin>
			{/** ---------------------------------------------------- */}
			{/** GLOBAL ALERT */}
			<GlobalAlert2
				isError={isErrorPostKey}
				isSuccess={isSuccessPostKey}
				requestType='POST'
				error={errorPostKey}
				name='Key'
			/>
			<GlobalAlert2
				isError={isErrorPutKey}
				isSuccess={isSuccessPutKey}
				requestType='PUT'
				error={errorPutKey}
				name='Key'
			/>
		</Drawer>
	);
}

export default KeysCreateEditForm;
