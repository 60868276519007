import React, { useState } from "react";
// ANT DESIGN COMPONENTS
import { Modal, Button, Spin } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useDispatch, useSelector } from "react-redux";
import {
	updateOpenAlertDownloadReport,
	updateOpenPdfViewer,
	updateChartReportTheme,
} from "../../redux/reports/reportsSlice";
// INTERFACES
import { RootState } from "../../app/store";
import { ReportsAlertDownloadModalInterface } from "../../interfaces/Reports.interface";

function ReportsAlertDownloadModal({
	setDownloadReport,
}: ReportsAlertDownloadModalInterface) {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [isLoading, setIsLoading] = useState(false);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { openModalDownloadReport, filters } = useSelector(
		(state: RootState) => state.report
	);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleIsLoading = () => {
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
		}, 1000);
	};

	const onClickCloseModal = () => {
		dispatch(updateOpenAlertDownloadReport(false));
	};

	const onClickDownloadCSV = async () => {
		handleIsLoading();
		setDownloadReport(true);
		dispatch(updateOpenAlertDownloadReport(false));
	};

	const onClickDownloadPDF = () => {
		dispatch(updateOpenPdfViewer(true));
		dispatch(updateChartReportTheme("light"));
		dispatch(updateOpenAlertDownloadReport(false));
	};
	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Modal
			onOk={onClickCloseModal}
			closable={false}
			onCancel={onClickCloseModal}
			open={openModalDownloadReport}
			footer={null}
		>
			<Spin spinning={isLoading}>
				<div>
					<div className='text-center'>
						<FontAwesomeIcon
							icon={faTriangleExclamation}
							className='generalStyles__warning generalStyles__deleteItemIcon'
						/>
						<div className='mt-3 mb-3'>
							Please note that reports download are audited. By continuing, you
							confirm that you are following your organizations best practices
							for exporting, using, and retaining data.{" "}
						</div>
					</div>
					<div className='generalStyles__flexEnd mt-4'>
						<div>
							{filters.displayDownloadCSVReport && (
								<Button
									htmlType='submit'
									className='buttonStyle__13 generalStyles__mlFix'
									onClick={onClickDownloadCSV}
								>
									{t("general.download")} CSV
								</Button>
							)}
							{filters.displayDownloadPDFReport && (
								<Button
									htmlType='submit'
									className='buttonStyle__17 generalStyles__mlFix'
									onClick={onClickDownloadPDF}
								>
									{t("general.download")} PDF
								</Button>
							)}
							<Button
								onClick={onClickCloseModal}
								className='buttonStyle__19 generalStyles__mlFix'
							>
								{t("general.close")}
							</Button>
						</div>
					</div>
				</div>
			</Spin>
		</Modal>
	);
}

export default ReportsAlertDownloadModal;
