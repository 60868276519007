import { faArrowsRotate, faArrowUpFromBracket, faBackward, faFileDownload, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import { RootState } from "../../../../app/store";
import {
	updateKeyRefreshTable,
	updateOpenModalCreateNewKey,
	updateFormCreateEdit,
	updateOpenImportKeys,
} from "../../../../redux/keys/keysSlice";
import { updateKeysAdapter } from "../../../../redux/adapters/adapterSlice";
import { AdapterInterface } from "../../../../interfaces/Adapter.interface";
import validatePermission from "../../../../utils/validatePermissions";
import GETJwtToken from "../../../../redux/authentication/authentication";
// ANT DESIGN COMPONENTS

function KeysHeader() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const BLANK_ADAPTER: AdapterInterface = {
		auth_type: "PASSWORD",
		dictionary: {},
		interface: {},
		bacnet_config: {},
		lift_type: "ELEVATOR",
		name: "",
		socket_address: "",
		type: "WEBSOCKET",
		protocol_type: "",
		version: "",
		auth_credentials: { username: "", password: "" },
	};

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const dispatch = useDispatch();
	const { permissions } = useSelector((state: any) => state.user);
	const { keysAdapter } = useSelector((state: RootState) => state.adapter);
	const [t] = useTranslation("global");
	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleOpenModalCreateNewKey = () => {
		dispatch(updateOpenModalCreateNewKey(true));
		dispatch(updateFormCreateEdit("create"));
	};
	const handleopenImportKeys = () => {
		dispatch(updateOpenImportKeys(true));
	};
	const handleReloadPage = () => {
		dispatch(updateKeyRefreshTable(true));
		setTimeout(() => {
			dispatch(updateKeyRefreshTable(false));
		}, 1000);
	};

	const handleReturn2Adapter = () => {
		dispatch(updateKeysAdapter(BLANK_ADAPTER))
	}

	const downloadTemplate = async () => {
		const token: string = await GETJwtToken();
		const url = `${process.env.REACT_APP_LIFT_NET_CLOUD_API_ONLINE}/adapters/csv/${keysAdapter.id}`;

		// eslint-disable-next-line no-return-await
		return await axios
			.post(url, {}, {
				responseType: "blob",
				headers: {
					Authorization: token,
					"Content-type": "application/json",
				},
			})
			.then((res: any) => {
				const data1 = new Blob([res?.data], {
					type: "application / vnd. MS Excel",
				});
				const csvURL = window.URL.createObjectURL(data1);
				const tempLink = document.createElement("a");
				tempLink.href = csvURL;
				tempLink.setAttribute(
					"download",
					`Keys Import for ${keysAdapter.name} ${moment()
						.tz(moment.tz.guess())
						.format("MMMM Do YYYY, HH:mm:ss")}.csv`
				);
				tempLink.click();
			});
	};

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<div className='generalStyles__pageHeader'>
				{validatePermission("keys-view", permissions) && (
					<div className='generalStyles__mlFix mt-1'>
						<Button
							className='buttonStyle__1'
							icon={
								<FontAwesomeIcon
									icon={faArrowsRotate}
									className='generalStyles__mrFix'
								/>
							}
							onClick={() => {
								handleReloadPage();
							}}
						>
							{t("userManagement.refreshTable")}
						</Button>
					</div>
				)}
				{validatePermission("keys-create", permissions) && (
					<div className='generalStyles__mlFix mt-1'>
						<Button
							className='buttonStyle__1'
							icon={
								<FontAwesomeIcon
									icon={faPlus}
									className='generalStyles__mrFix'
								/>
							}
							onClick={handleOpenModalCreateNewKey}
						>
							{t("key.createNewKey")}
						</Button>
					</div>
				)}
				{validatePermission("keys-create", permissions) && (
					<div className='generalStyles__mlFix mt-1'>
						<Button
							className='buttonStyle__1'
							icon={
								<FontAwesomeIcon
									icon={faFileDownload}
									className='generalStyles__mrFix'
								/>
							}
							onClick={downloadTemplate}
						>
							{t("key.downloadTemplate")}
						</Button>
					</div>
				)}
				{validatePermission("keys-create", permissions) && (
					<div className='generalStyles__mlFix mt-1'>
						<Button
							className='buttonStyle__1'
							icon={
								<FontAwesomeIcon
									icon={faArrowUpFromBracket}
									className='generalStyles__mrFix'
								/>
							}
							onClick={handleopenImportKeys}
						>
							{t("key.importKeys")}
						</Button>
					</div>
				)}
				{validatePermission("keys-view", permissions) && (
					<div className='generalStyles__mlFix mt-1'>
						<Button
							className='buttonStyle__1'
							icon={
								<FontAwesomeIcon
									icon={faBackward}
									className='generalStyles__mrFix'
								/>
							}
							onClick={handleReturn2Adapter}
						>
							{t("key.return2Adapter")}
						</Button>
					</div>
				)}
			</div>
		</div>
	);
}

export default KeysHeader;
