import React from "react";
// NAVIGATION
import { useNavigate, useLocation } from "react-router-dom";
// ANT DESIGN COMPONENTS
import { Breadcrumb } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser } from "@fortawesome/free-solid-svg-icons";

library.add(faUser);

function NotificationUsersBreadcrumb() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");
	const navigate = useNavigate();
	const location = useLocation();
	const idBank = new URLSearchParams(location.search).get("idBank");
	const idAlert = new URLSearchParams(location.search).get("idAlert");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const navigateToBanks = () => {
		navigate({
			pathname: "/notification-management/notifications-banks",
		});
	};

	const navigateToAlerts = () => {
		navigate({
			pathname: "/notification-management/notifications-alerts",
			search: `?idBank=${idBank}`,
		});
	};

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Breadcrumb className=''>
			<Breadcrumb.Item>{t("general.notificationManagement")}</Breadcrumb.Item>
			{idBank && idBank !== "" && (
				<Breadcrumb.Item
					className='generalStyles__breadcrumbItemInactive'
					onClick={navigateToBanks}
				>
					{t("general.banks")}
				</Breadcrumb.Item>
			)}
			{idAlert && idAlert !== "" && (
				<Breadcrumb.Item
					className='generalStyles__breadcrumbItemInactive'
					onClick={navigateToAlerts}
				>
					{t("general.alerts")}
				</Breadcrumb.Item>
			)}
			<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
				{t("general.alerts")}
			</Breadcrumb.Item>
		</Breadcrumb>
	);
}

export default NotificationUsersBreadcrumb;
