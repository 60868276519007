import React from "react";
// TIMEZONE
import moment from "moment-timezone";
// REDUX
import { useSelector } from "react-redux";
// REACT PDF
import {
	Image,
	Text,
	View,
	Page,
	Document,
	StyleSheet,
	PDFViewer,
} from "@react-pdf/renderer";
import Logo from "../../../assets/logo/LiftNetLogo.png";
// INTERFACES
import { RootState } from "../../../app/store";

function PDFGenerator() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	// Create styles
	const styles = StyleSheet.create({
		page: {
			fontSize: 11,
			paddingTop: 20,
			paddingLeft: 40,
			paddingRight: 40,
			lineHeight: 1.5,
			flexDirection: "column",
		},

		pageFilters: {
			paddingTop: 20,
			paddingLeft: 40,
			paddingRight: 40,
		},

		spaceBetween: {
			flex: 1,
			flexDirection: "row",
			alignItems: "center",
			justifyContent: "space-between",
		},

		box1: {
			flexDirection: "row",
			marginTop: "5px",
		},

		topFix: {
			marginTop: "10px",
		},

		titleContainer: { flexDirection: "row", marginTop: 24 },

		logo: { width: 90 },

		text1: {
			fontSize: 18,
			fontWeight: "bold",
			textAlign: "center",
			color: "#2968c0",
		},
		text2: {
			fontSize: 9,
			color: "#2968c0",
			width: "150px",
			fontWeight: "bold",
		},
		text3: { fontSize: 7, paddingTop: "3px", width: "400px" },
	});

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { filters } = useSelector((state: RootState) => state.report);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<PDFViewer style={{ width: "100%", height: "75vh" }}>
				<Document>
					{/** ******************************************************************* */}
					{/** PAGE MAIN */}
					<Page size='A4' style={styles.page}>
						<View style={styles.titleContainer}>
							<View style={styles.spaceBetween}>
								<Image style={styles.logo} src={Logo} />
								<Text style={styles.text1}>{filters.reportTitle}</Text>{" "}
							</View>
						</View>
						<View style={styles.titleContainer}>
							<View style={styles.spaceBetween}>
								<View>
									<Text style={styles.text2}>
										{filters.levelTypeName === "LIFTS" && (
											<>
												{filters.bankName} / {filters.liftName}
											</>
										)}
										{filters.levelTypeName !== "LIFTS" && (
											<>
												{filters.organizationName} / {filters.serverName}
											</>
										)}
									</Text>
									<Text>
										{moment().format("dddd, MMMM Do YYYY, h:mm:ss a")}
									</Text>
								</View>
							</View>
						</View>
						<View>
							<View style={styles.topFix} />
							{filters &&
								filters.urlChartImage &&
								filters.urlChartImage !== "" && (
									<Image src={filters.urlChartImage} />
								)}
						</View>
					</Page>
				</Document>
			</PDFViewer>
		</div>
	);
}

export default PDFGenerator;
