/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// INTERFACES
import { AdapterInterface } from "../../interfaces/Adapter.interface";

export interface AdapterState {
	adapter: AdapterInterface;
	keysAdapter: AdapterInterface;
	openDrawerAdapter: boolean;
	openModalCreateNewAdapter: boolean;
	adapterRefreshTable: boolean;
	openModalDeleteAdapter: boolean;
	formCreateEdit: string;
	openDrawerAssignActions: boolean;
}

const initialState: AdapterState = {
	adapter: {
		auth_type: "PASSWORD",
		dictionary: {},
		interface: {},
		lift_type: "ELEVATOR",
		name: "",
		protocol_type: "",
		socket_address: "",
		type: "WEBSOCKET",
		version: "",
		auth_credentials: { username: "", password: "" },
	},
	keysAdapter: {
		auth_type: "PASSWORD",
		dictionary: {},
		interface: {},
		lift_type: "ELEVATOR",
		name: "",
		protocol_type: "",
		socket_address: "",
		type: "WEBSOCKET",
		version: "",
		auth_credentials: { username: "", password: "" },
	},
	openDrawerAdapter: false,
	openModalCreateNewAdapter: false,
	adapterRefreshTable: false,
	openModalDeleteAdapter: false,
	formCreateEdit: "create",
	openDrawerAssignActions: false,
};

export const adapterSlice = createSlice({
	name: "adapter",
	initialState,
	reducers: {
		updateAdapter: (state, action: PayloadAction<AdapterInterface>) => {
			state.adapter = action.payload;
		},
		updateKeysAdapter: (state, action: PayloadAction<AdapterInterface>) => {
			state.keysAdapter = action.payload;
		},
		updateOpenDrawerAdapter: (state, action: PayloadAction<boolean>) => {
			state.openDrawerAdapter = action.payload;
		},
		updateOpenModalCreateNewAdapter: (
			state,
			action: PayloadAction<boolean>
		) => {
			state.openModalCreateNewAdapter = action.payload;
		},
		updateAdapterRefreshTable: (state, action: PayloadAction<boolean>) => {
			state.adapterRefreshTable = action.payload;
		},
		updateOpenModalDeleteAdapter: (state, action: PayloadAction<boolean>) => {
			state.openModalDeleteAdapter = action.payload;
		},
		updateFormCreateEdit: (state, action: PayloadAction<string>) => {
			state.formCreateEdit = action.payload;
		},
		updateOpenDrawerAssignActions: (state, action: PayloadAction<boolean>) => {
			state.openDrawerAssignActions = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	updateAdapter,
	updateKeysAdapter,
	updateOpenDrawerAdapter,
	updateOpenModalCreateNewAdapter,
	updateAdapterRefreshTable,
	updateOpenModalDeleteAdapter,
	updateFormCreateEdit,
	updateOpenDrawerAssignActions,
} = adapterSlice.actions;

export default adapterSlice.reducer;
