import React from "react";
// ANT DESIGN COMPONENTS
import { InputNumber } from "antd";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateFilters } from "../../../redux/reports/reportsSlice";
// INTERFACES
import { RootState } from "../../../app/store";
import { ReportSliceFiltersInterface } from "../../../interfaces/Reports.interface";

function ReportsFilterSelectSeconds() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const labels: any = {
		"0": "00:00",
		"30": "00:30",
		"60": "01:00",
		"90": "01:30",
		"120": "02:00",
		"150": "02:30",
		"180": "03:00",
		"210": "03:30",
		"240": "04:00",
		"270": "04:30",
		"300": "05:00",
		"330": "05:30",
		"360": "06:00",
		"390": "06:30",
		"420": "07:00",
		"450": "07:30",
		"480": "08:00",
		"510": "08:30",
		"540": "09:00",
		"570": "09:30",
		"600": "10:00",
	};

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { filters } = useSelector((state: RootState) => state.report);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onChangeTime = (newValue: number) => {
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		copy.dateTimeSeconds = newValue;
		dispatch(updateFilters(copy));
	};

	const getInputLabel = (value: any) => labels[value];
	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<InputNumber
				style={{ width: "100%" }}
				defaultValue={100}
				min={0}
				max={600}
				step={30}
				value={filters.dateTimeSeconds}
				formatter={(value) => `${getInputLabel(value)}`}
				onChange={(e: any) => {
					onChangeTime(e);
				}}
				size='small'
			/>
		</div>
	);
}

export default ReportsFilterSelectSeconds;
