/* eslint-disable */
import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Select, Tooltip } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { useLazyGetServersQuery } from "../../../redux/servers/serverAPI";
import {
	updateFilters,
	updateReportRefreshTable,
} from "../../../redux/reports/reportsSlice";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// INTERFACES
import { ServerInterface } from "../../../interfaces/EdgeManagement.interface";
import { ReportSliceFiltersInterface } from "../../../interfaces/Reports.interface";
import { RootState } from "../../../app/store";

function ReportsFilterGetServers() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const { Option } = Select;
	const dispatch = useDispatch();
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [servers, setServers] = useState<ServerInterface[]>([]);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { filters } = useSelector((state: RootState) => state.report);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetServers, { data, isLoading, isError, isFetching }] =
		useLazyGetServersQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const initialData = async () => {
		const token = await GETJwtToken();
		triggerGetServers({
			page: 1,
			limit: 9999,
			token,
		});
	};

	const onChangeServer = (newValue: string) => {
		const copy: ReportSliceFiltersInterface = structuredClone(filters);
		const index = servers.findIndex((object: any) => object.id === newValue);
		if (servers[index]) {
			copy.serverId = servers[index].id;
			copy.serverName = servers[index].name;
			if (servers[index].org) {
				copy.organizationId = servers[index].org.id;
				copy.organizationName = servers[index].org.name;
			}
		}
		dispatch(updateFilters(copy));
	};

	const onClickSearch = () => {
		dispatch(updateReportRefreshTable(true));
		setTimeout(() => {
			dispatch(updateReportRefreshTable(false));
		}, 1000);
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		initialData();
	}, []);

	useEffect(() => {
		const newData = structuredClone(data);
		if (newData && newData.data) {
			setServers(newData.data);
		}
	}, [data]);

	useEffect(() => {
		setServers([]);
	}, [isError]);

	useEffect(() => {
		if (servers && servers[0] && servers[0].id && filters.serverId === "") {
			onChangeServer(servers[0].id);
			onClickSearch();
		}
	}, [servers]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='generalStyles__width100'>
			<Tooltip title={servers.length === 0 ? t("report.noServers") : ""}>
				<Select
					className='generalStyles__width100'
					disabled={servers.length === 0}
					showSearch
					optionFilterProp='children'
					size='small'
					placeholder={t("report.selectServer")}
					loading={isLoading || isFetching}
					value={filters.serverId === "" ? null : filters.serverId}
					filterOption={(input: string, option: any) =>
						option.children.toLowerCase().includes(input.toLowerCase())
					}
					onChange={onChangeServer}
				>
					{servers.map((server: ServerInterface) => (
						<Option key={server.id} value={server.id}>
							{server.name}
						</Option>
					))}
				</Select>
			</Tooltip>
		</div>
	);
}

export default ReportsFilterGetServers;
