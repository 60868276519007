import React from "react";
// ANT DESIGN COMPONENTS
import { Input } from "antd";
// INTERFACES
import { FormInputTextInterface } from "../../../interfaces/Global.interface";

function FormInputText({
	inputValue,
	inputOnChange,
	inputLabelName,
	inputVariableName,
	isRequired,
}: FormInputTextInterface) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='col-md-12 mt-3'>
			<div className='generalStyles__flex'>
				<div className='generalStyles__inputFlexNameLarge'>
					{isRequired ? (
						<span className='generalStyles__inputFlexRequired'>*</span>
					) : (
						<span style={{ marginLeft: "8px" }} />
					)}
					<span className='drawer__text'>{inputLabelName}</span>
				</div>
				<div className='generalStyles__width100'>
					<Input
						className='generalStyles__input'
						size='small'
						value={inputValue}
						onChange={(event: React.FormEvent<HTMLInputElement>) => {
							inputOnChange(event.currentTarget.value, inputVariableName);
						}}
					/>
				</div>
				<div className='generalStyles__infoTooltipIconBlank' />
			</div>
		</div>
	);
}

export default FormInputText;
