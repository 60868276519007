import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
// ANT DESIGN COMPONENTS
import { Breadcrumb } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateBreadcrumbTitle } from "../../redux/home/homeSlice";
// COMPONENTS
import OrganizationBreadcrumb from "../organizationManagement/organizations/OrganizationBreadcrumb";
import ServersBreadcrumb from "../edgeManagement/servers/ServersBreadcrumb";
import BanksBreadcrumb from "../edgeManagement/banks/BanksBreadcrumb";
import NotificationAlertsBreadcrumb from "../notificationManagement/alerts/NotificationAlertsBreadcrumb";
import NotificationUsersBreadcrumb from "../notificationManagement/users/NotificationUsersBreadcrumb";

function LayoutBreadcrumb() {
	// ************************************************ */
	// LOCAL STORAGE AND VARIABLES ******************** */
	const location = useLocation();
	const [t] = useTranslation("global");
	const { breadcrumbTitle } = useSelector((state: any) => state.home);
	const { offsetTypeName } = useSelector(
		(state: any) => state.configurationFile
	);
	const { renderComponent } = useSelector((state: any) => state.channel);
	const dispatch = useDispatch();

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleChangeHeaderTitle = (title: string) => {
		localStorage.setItem("headerTitle", title);
		dispatch(updateBreadcrumbTitle(title));
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (location && location.pathname) {
			if (location.pathname === "/edge-management/configuration-catalog") {
				handleChangeHeaderTitle("configurationCatalog");
			} else if (
				location.pathname ===
				"/edge-management/configuration-catalog/definitions"
			) {
				handleChangeHeaderTitle("definitions");
			} else if (
				location.pathname === "/edge-management/configuration-catalog/offsets"
			) {
				handleChangeHeaderTitle("offsets");
			}
		}
	}, [location]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Breadcrumb className='generalStyles__breadcrumb'>
			{/** ************************************************* */}
			{/** SIMPLE BREADCRUMB */}
			{(localStorage.getItem("headerTitle") === "dashboard" ||
				breadcrumbTitle === "dashboard") && (
				<>
					<Breadcrumb.Item>{t("header.dashboard")} /</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "users" ||
				breadcrumbTitle === "users") && (
				<>
					<Breadcrumb.Item>
						{t("header.organizationManagement")}
					</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("header.users")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "roles" ||
				breadcrumbTitle === "roles") && (
				<>
					<Breadcrumb.Item>
						{t("header.organizationManagement")}
					</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("header.roles")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "rootPermissions" ||
				breadcrumbTitle === "rootPermissions") && (
				<>
					<Breadcrumb.Item>{t("header.tools")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("header.rootPermissions")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "offsetTypeConfiguration" ||
				breadcrumbTitle === "offsetTypeConfiguration") && (
				<>
					<Breadcrumb.Item>{t("header.tools")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("header.offsetTypeConfiguration")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") ===
				"specialSwitchesConfiguration" ||
				breadcrumbTitle === "specialSwitchesConfiguration") && (
				<>
					<Breadcrumb.Item>{t("header.tools")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("header.specialSwitchesConfiguration")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") ===
				"specialSwitchesCatalogConfiguration" ||
				breadcrumbTitle === "specialSwitchesCatalogConfiguration") && (
				<>
					<Breadcrumb.Item>{t("header.tools")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("header.specialSwitchesCatalogConfiguration")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "dataBlocksConfiguration" ||
				breadcrumbTitle === "dataBlocksConfiguration") && (
				<>
					<Breadcrumb.Item>{t("header.tools")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("header.dataBlocks")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "vendorsConfiguration" ||
				breadcrumbTitle === "vendorsConfiguration") && (
				<>
					<Breadcrumb.Item>{t("header.tools")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("header.vendors")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "actionsConfiguration" ||
				breadcrumbTitle === "actionsConfiguration") && (
				<>
					<Breadcrumb.Item>{t("header.tools")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("header.actions")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "messageType" ||
				breadcrumbTitle === "messageType") && (
				<>
					<Breadcrumb.Item>{t("header.tools")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("header.messageType")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "message" ||
				breadcrumbTitle === "message") && (
				<>
					<Breadcrumb.Item>{t("header.tools")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("header.message")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "iconDefinitionConfiguration" ||
				breadcrumbTitle === "iconDefinitionConfiguration") && (
				<>
					<Breadcrumb.Item>{t("header.tools")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("sideBar.iconDefinition")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "permissions" ||
				breadcrumbTitle === "permissions") && (
				<>
					<Breadcrumb.Item>{t("header.tools")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("sideBar.permissions")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "lifts" ||
				breadcrumbTitle === "lifts") && (
				<>
					<Breadcrumb.Item>{t("header.edgeManagement")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("header.lifts")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "adapters" ||
				breadcrumbTitle === "adapters") && (
				<>
					<Breadcrumb.Item>{t("header.edgeManagement")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("header.adapters")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "groups" ||
				breadcrumbTitle === "groups") && (
				<>
					<Breadcrumb.Item>{t("header.edgeManagement")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("header.groups")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "channels" ||
				breadcrumbTitle === "channels") && (
				<>
					<Breadcrumb.Item>{t("header.edgeManagement")}</Breadcrumb.Item>
					{renderComponent === "servers" && (
						<Breadcrumb.Item>
							<Link to='servers'>{t("header.servers")}</Link>
						</Breadcrumb.Item>
					)}
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("header.channels")}
					</Breadcrumb.Item>
				</>
			)}

			{(localStorage.getItem("headerTitle") === "configurationCatalog" ||
				breadcrumbTitle === "configurationCatalog") && (
				<>
					<Breadcrumb.Item>{t("header.edgeManagement")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("header.configurationCatalog")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "definitions" ||
				breadcrumbTitle === "definitions") && (
				<>
					<Breadcrumb.Item>{t("header.edgeManagement")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						<Link to='configuration-catalog'>
							{t("header.configurationCatalog")}
						</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("header.definitions")} - {offsetTypeName}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "offsets" ||
				breadcrumbTitle === "offsets") && (
				<>
					<Breadcrumb.Item>{t("header.edgeManagement")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						<Link to='configuration-catalog'>
							{t("header.configurationCatalog")}
						</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("header.offsets")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "reportGeneral" ||
				breadcrumbTitle === "reportGeneral") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("header.generalReports")}
					</Breadcrumb.Item>
				</>
			)}

			{(localStorage.getItem("headerTitle") === "reportFault" ||
				breadcrumbTitle === "reportFault") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("header.fault")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "reportFaultsReportGeneral" ||
				breadcrumbTitle === "reportFaultsReportGeneral") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item>{t("header.fault")}s</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("general.general")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "reportFaultsReportLog" ||
				breadcrumbTitle === "reportFaultsReportLog") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item>{t("header.fault")}s</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("general.faultLogByBank")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") ===
				"reportFaultsReportRecentFault" ||
				breadcrumbTitle === "reportFaultsReportRecentFault") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item>{t("header.fault")}s</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("general.recentFaults")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "reportFaultsChartDay" ||
				breadcrumbTitle === "reportFaultsChartDay") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item>{t("header.fault")}s</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("general.faultByDay")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "reportFaultsChartFault" ||
				breadcrumbTitle === "reportFaultsChartFault") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item>{t("header.fault")}s</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("general.faultByType")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "reportFaultsChartFloor" ||
				breadcrumbTitle === "reportFaultsChartFloor") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item>{t("header.fault")}s</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("general.faultByFloor")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "reportFaultsChartLift" ||
				breadcrumbTitle === "reportFaultsChartLift") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item>{t("header.fault")}s</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("general.faultByLift")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") ===
				"reportTrafficChartHallButtonCountsByFloor" ||
				breadcrumbTitle === "reportTrafficChartHallButtonCountsByFloor") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item>{t("header.traffic")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("general.byFloor")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") ===
				"reportTrafficChartHallButtonCountsByTimeOfDay" ||
				breadcrumbTitle ===
					"reportTrafficChartHallButtonCountsByTimeOfDay") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item>{t("header.traffic")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("general.byTimeOfDay")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") ===
				"reportTrafficChartTripsToAndFromAFloor" ||
				breadcrumbTitle === "reportTrafficChartTripsToAndFromAFloor") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item>{t("header.traffic")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("general.tripsToAndFromFloor")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") ===
				"reportTrafficReportDetailedSummary" ||
				breadcrumbTitle === "reportTrafficReportDetailedSummary") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item>{t("header.traffic")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("general.detailedSummaryReport")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") ===
				"reportTrafficReportHallCallSummaryReport" ||
				breadcrumbTitle === "reportTrafficReportHallCallSummaryReport") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item>{t("header.traffic")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("general.hallCallSummaryReport")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") ===
				"reportTrafficChartAverageByFloor" ||
				breadcrumbTitle === "reportTrafficChartAverageByFloor") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item>{t("header.traffic")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("general.averageByFloor")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") ===
				"reportTrafficChartAverageByTimeOfDay" ||
				breadcrumbTitle === "reportTrafficChartAverageByTimeOfDay") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item>{t("header.traffic")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("general.averageByTimeOfDay")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") ===
				"reportTrafficChartDistributionByHourlyInterval" ||
				breadcrumbTitle ===
					"reportTrafficChartDistributionByHourlyInterval") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item>{t("header.traffic")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("general.distributionByHourlyInterval")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") ===
				"reportTrafficChartDistributionDetailedTotal" ||
				breadcrumbTitle === "reportTrafficChartDistributionDetailedTotal") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item>{t("header.traffic")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("general.distributionDetailedTotal")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") ===
				"reportTrafficChartDistributionDetailedDirection" ||
				breadcrumbTitle ===
					"reportTrafficChartDistributionDetailedDirection") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item>{t("header.traffic")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("general.distributionDetailedDirection")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") ===
				"reportTrafficReportLongestWaitTimeList" ||
				breadcrumbTitle === "reportTrafficReportLongestWaitTimeList") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item>{t("header.traffic")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("general.longestWaitTimes")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "reportCarUseChartByHour" ||
				breadcrumbTitle === "reportCarUseChartByHour") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item>{t("general.carUse")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("general.carUseByHour")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") ===
				"reportCarUseChartComparisons" ||
				breadcrumbTitle === "reportCarUseChartComparisons") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item>{t("general.carUse")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("general.carUseComparisons")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "reportCarUseChartTimes" ||
				breadcrumbTitle === "reportCarUseChartTimes") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item>{t("general.carUse")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("general.carUseTimes")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") ===
				"reportCarServiceChartServiceLog" ||
				breadcrumbTitle === "reportCarServiceChartServiceLog" ||
				localStorage.getItem("headerTitle") === "reportCarServiceServiceLog" ||
				breadcrumbTitle === "reportCarServiceServiceLog") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item>{t("general.carService")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("general.serviceLog")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "reportCarServiceReportLog" ||
				breadcrumbTitle === "reportCarServiceReportLog") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item>{t("general.carService")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("general.log")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") ===
				"reportCarServiceReportStatistics" ||
				breadcrumbTitle === "reportCarServiceReportStatistics") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item>{t("general.carService")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("general.statistics")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") ===
				"reportGeneralReportCurrentOutOfService" ||
				breadcrumbTitle === "reportGeneralReportCurrentOutOfService") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item>{t("general.general")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("general.currentOutOfService")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") ===
				"reportGeneralReportPerformance" ||
				breadcrumbTitle === "reportGeneralReportPerformance") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item>{t("general.general")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("general.performance")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") ===
				"reportGeneralReportEscalatorInUse" ||
				breadcrumbTitle === "reportGeneralReportEscalatorInUse") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item>{t("general.general")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("general.escalatorInUse")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "reportFrequencyReportCar" ||
				breadcrumbTitle === "reportFrequencyReportCar") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item>{t("general.frequency")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("general.car")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "reportFrequencyReportFault" ||
				breadcrumbTitle === "reportFrequencyReportFault") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item>{t("general.frequency")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("general.fault")}
					</Breadcrumb.Item>
				</>
			)}

			{(localStorage.getItem("headerTitle") ===
				"reportGeneralReportFireService" ||
				breadcrumbTitle === "reportGeneralReportFireService") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item>{t("general.general")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("general.fireService")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") ===
				"reportGeneralReportBeneficialUsage" ||
				breadcrumbTitle === "reportGeneralReportBeneficialUsage") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item>{t("general.general")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("general.beneficialUsage")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "reportTraffic" ||
				breadcrumbTitle === "reportTraffic") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("header.traffic")}
					</Breadcrumb.Item>
				</>
			)}

			{(localStorage.getItem("headerTitle") === "reportCarUsage" ||
				breadcrumbTitle === "reportCarUsage") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("header.carUsage")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "reportService" ||
				breadcrumbTitle === "reportService") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("header.service")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "reportBeneficial" ||
				breadcrumbTitle === "reportBeneficial") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("header.beneficial")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "reportPerformance" ||
				breadcrumbTitle === "reportPerformance") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("header.performance")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "reportOutOfService" ||
				breadcrumbTitle === "reportOutOfService") && (
				<>
					<Breadcrumb.Item>{t("header.reports")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("header.outOfService")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "notificationsBanks" ||
				breadcrumbTitle === "notificationsBanks") && (
				<>
					<Breadcrumb.Item>
						{t("general.notificationManagement")}
					</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("general.banks")}
					</Breadcrumb.Item>
				</>
			)}
			{(localStorage.getItem("headerTitle") === "notificationsSchedules" ||
				breadcrumbTitle === "notificationsSchedules") && (
				<>
					<Breadcrumb.Item>{t("general.notifications")}</Breadcrumb.Item>
					<Breadcrumb.Item className='generalStyles__breadcrumbItem'>
						{t("general.schedules")}
					</Breadcrumb.Item>
				</>
			)}
			{/** ************************************************* */}
			{/** COMPONENT BREADCRUMB */}
			{(localStorage.getItem("headerTitle") === "organizations" ||
				breadcrumbTitle === "organizations") && <OrganizationBreadcrumb />}
			{(localStorage.getItem("headerTitle") === "servers" ||
				breadcrumbTitle === "servers") && <ServersBreadcrumb />}
			{(localStorage.getItem("headerTitle") === "banks" ||
				breadcrumbTitle === "banks") && <BanksBreadcrumb />}
			{(localStorage.getItem("headerTitle") === "notificationsAlerts" ||
				breadcrumbTitle === "notificationsAlerts") && (
				<NotificationAlertsBreadcrumb />
			)}
			{(localStorage.getItem("headerTitle") === "notificationsUsers" ||
				breadcrumbTitle === "notificationsUsers") && (
				<NotificationUsersBreadcrumb />
			)}
		</Breadcrumb>
	);
}

export default LayoutBreadcrumb;
