import React, { useState, useEffect } from "react";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import { updateFilters } from "../../../redux/reports/reportsSlice";
import { usePostTrafficDistributionMutation } from "../../../redux/reports/chartsAPI";
// COMPONENTS
import ReportsHeader from "../ReportsHeader";
import ChartBar from "../charts/ChartBar";
import ChartPie from "../charts/ChartPie";
import ChartLine from "../charts/ChartLine";
// AUTHORIZATION
import validatePermission from "../../../utils/validatePermissions";
import UnauthorizedPage from "../../unauthorized/UnauthorizedPage";
// INTERFACES
import { RootState } from "../../../app/store";
import { ReportSliceFiltersInterface } from "../../../interfaces/Reports.interface";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// UTILS
import {
	getTotalCarsInBank,
	GenerateDistributionDirectionData,
} from "../utils";

const ReportTrafficChartDistributionDetailedDirection = () => {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [chartData, setChartData] = useState<any>([[], []]);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { permissions } = useSelector((state: any) => state.user);
	const { theme } = useSelector((state: any) => state.home);
	const { filters, reportRefreshTable } = useSelector(
		(state: RootState) => state.report
	);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetChart, { data, isLoading }] =
		usePostTrafficDistributionMutation();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const getChartData = async () => {
		const token = await GETJwtToken();
		triggerGetChart({
			token,
			body: {
				bank_id: filters.bankId,
				start_date: filters.startDate,
				end_date: filters.endDate,
				timezone: filters.timezoneCode,
				chart_by: "DIRECTION",
			},
		});
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (data && data.results) {
			const response = GenerateDistributionDirectionData(data.results);
			setChartData([data.results, response]);
			setChartData([response[0], response]);
		} else {
			setChartData([
				{ value: 0, name: "", tag: "" },
				[[{ value: 0, name: "", tag: "" }]],
			]);
		}
	}, [data]);

	useEffect(() => {
		if (reportRefreshTable) {
			getChartData();
		}
	}, [reportRefreshTable]);

	useEffect(() => {
		if (chartData && chartData[0] && chartData[0].length > 0) {
			const response = getTotalCarsInBank(chartData[0]);
			const copy: ReportSliceFiltersInterface = structuredClone(filters);
			copy.selectedCarsList = response;
			copy.carsList = response;
			dispatch(updateFilters(copy));
		}
	}, [chartData]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			{validatePermission("fault-report", permissions) ? (
				<div className={`layout__contentPage__${theme}`}>
					<ReportsHeader
						reportDefaultData={{
							reportType: "CHART",
							levelTypeName: "BANKS",
							displaySelectTypeLevel: false,
							liftType: "ALL",
							defaultColumns: [],
							defaultOffsets: [],
							infoDescriptionText: "report.infoDescriptionText2",
							displayDownloadCSVReport: false,
							displayDownloadPDFReport: true,
							reportTitle: "",
							displayAdvanceFiltersExclusions: false,
							displayAdvanceFiltersOffsets: false,
							disabledOffsets: true,
							useTablePagination: true,
							displayDateByRange: false,
							displayDate: true,
							displaySelectLiftType: true,
							displayAdvanceFilterCharts: true,
							displayAdvanceFiltersTable: false,
							displayRecentWeeksPicket: false,
							displayInputLimitItems: false,
							displayChartMirror: false,
							chartMirror: false,
							chartType: "bar_vertical",
							dateTypeOptions: [
								{ id: "TODAY", name: "Day" },
								{ id: "WEEK", name: "Week" },
								{ id: "MONTH", name: "Month" },
								{ id: "QUARTER", name: "Quarter" },
								{ id: "YEAR", name: "Year" },
							],
							dateDaysLimit: 0,
							displayMinimumActivation: false,
							dateWeeksLimit: 0,
							dateMonthsLimit: 0,
							dateQuarterLimit: 0,
							dateYearsLimit: 0,
							dateType: "MONTH",
						}}
					/>
					{(filters.chartType === "line" ||
						filters.chartType === "line_natural" ||
						filters.chartType === "step_before" ||
						filters.chartType === "step_after") && <ChartLine />}
					{filters.chartType === "pie" && <ChartPie charData={chartData[1]} />}
					{(filters.chartType === "bar_vertical" ||
						filters.chartType === "bar_stack_vertical" ||
						filters.chartType === "bar_horizontal" ||
						filters.chartType === "bar_stack_horizontal") && (
						<>
							{chartData && chartData[1] && chartData[1][0] && (
								<ChartBar
									data={chartData[1][0]}
									isLoading={isLoading}
									bottomLabel='PERCENTAGE'
									leftLabel='CATEGORY'
									topLabel='UP HALL CALLS'
									topLabelIcon='UP'
								/>
							)}
							{chartData && chartData[1] && chartData[1][1] && (
								<ChartBar
									data={chartData[1][1]}
									isLoading={isLoading}
									bottomLabel='PERCENTAGE'
									leftLabel='CATEGORY'
									topLabel='DOWN HALL CALLS'
									topLabelIcon='DOWN'
								/>
							)}
						</>
					)}
				</div>
			) : (
				<UnauthorizedPage />
			)}
		</div>
	);
};

export default ReportTrafficChartDistributionDetailedDirection;
