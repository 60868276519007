import React from "react";
// REDUX
import { useSelector } from "react-redux";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// COMPONENTS
import ReportsFilterLevelType from "../filters/ReportsFilterLevelType";
import ReportsFilterAdvanceDrawerBanks from "../filters/ReportsFilterAdvanceDrawerBanks";
import ReportsFilterGetServers from "../filters/ReportsFilterGetServers";
import ReportsFilterGetOrganizations from "../filters/ReportsFilterGetOrganizations";
import ReportsFilterGetLifts from "../filters/ReportsFilterGetLifts";
import ReportsFilterLiftType from "../filters/ReportsFilterLiftType";

// INTERFACES
import { RootState } from "../../../app/store";

function ReportsDrawerAdvanceFilters() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);
	const { filters } = useSelector((state: RootState) => state.report);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */

	// ************************************************ */
	// USE EFFECT ************************************* */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className={`drawer__box__${theme}`}>
			{/* SELECT LEVEL TYPE */}
			<div className='generalStyles__flex mt-3'>
				<div className='generalStyles__inputFlexNameLarge'>
					<span className='generalStyles__inputFlexRequired'>*</span>
					<span className='drawer__title'>{t("general.levelType")}:</span>
				</div>
				<div className='drawer__textContainer generalStyles__width100'>
					<ReportsFilterLevelType />
				</div>
			</div>
			{/** SELECT CASCADER LEVEL */}
			<div className='generalStyles__flex mt-3'>
				<div className='generalStyles__inputFlexNameLarge'>
					<span className='generalStyles__inputFlexRequired'>*</span>
					<span className='drawer__title'>
						{filters.levelTypeName.charAt(0).toUpperCase() +
							filters.levelTypeName.slice(1).toLowerCase()}
						:
					</span>
				</div>
				<div className='drawer__textContainer generalStyles__width100'>
					{filters.levelTypeName === "SERVERS" && <ReportsFilterGetServers />}
					{filters.levelTypeName === "BANKS" && (
						<ReportsFilterAdvanceDrawerBanks />
					)}
					{filters.levelTypeName === "ORGANIZATIONS" && (
						<ReportsFilterGetOrganizations />
					)}
					{filters.levelTypeName === "LIFTS" && <ReportsFilterGetLifts />}
				</div>
			</div>
			{/* LIFT TYPE */}
			{filters.displaySelectLiftType && (
				<div className='generalStyles__flex mt-3'>
					<div className='generalStyles__inputFlexNameLarge'>
						<span className='generalStyles__inputFlexRequired'>*</span>
						<span className='drawer__title'>
							{t("edgeManagement.lifts.liftType")}:
						</span>
					</div>
					<div className='drawer__textContainer generalStyles__width100'>
						<ReportsFilterLiftType />
					</div>
				</div>
			)}
		</div>
	);
}

export default ReportsDrawerAdvanceFilters;
