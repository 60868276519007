/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface NotificationState {
	notification: any;
	levelBreadcrumb: number;
}

const initialState: NotificationState = {
	notification: {},
	levelBreadcrumb: 0,
};

export const notificationSlice = createSlice({
	name: "notification",
	initialState,
	reducers: {
		updateNotification: (state, action: PayloadAction<any>) => {
			state.notification = action.payload;
		},
		updateLevelbreadcrumb: (state, action: PayloadAction<number>) => {
			state.levelBreadcrumb = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { updateNotification, updateLevelbreadcrumb } =
	notificationSlice.actions;

export default notificationSlice.reducer;
