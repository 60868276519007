import React, { useEffect, useState } from "react";
// MUI CHARTS
import { PieChart } from "@mui/x-charts/PieChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
import { styled } from "@mui/material/styles";
import { useDrawingArea } from "@mui/x-charts/hooks";
// REDUX
import { useSelector } from "react-redux";
// CSS CUSTOM STYLES
import {
	getChartBackgroundColor,
	getChartTextColor,
	getChartTicklineColor,
	generatePieData,
} from "../utils";
// INTERFACES
import { RootState } from "../../../app/store";

function ChartPie({ charData }: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [data, setData] = useState([]);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);
	const { filters } = useSelector((state: RootState) => state.report);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const StyledText = styled("text")(() => ({
		fill: "white",
		textAnchor: "middle",
		dominantBaseline: "central",
		fontSize: 20,
	}));

	function PieCenterLabel({ children }: { children: React.ReactNode }) {
		const { width, height, left, top } = useDrawingArea();
		return (
			<StyledText x={left + width / 2} y={top + height / 2}>
				{children}
			</StyledText>
		);
	}
	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		if (
			filters &&
			filters.selectedCarsList &&
			filters.selectedCarsList.length > 0
		) {
			setData(generatePieData(charData[0], filters.selectedCarsList));
		} else {
			setData([]);
		}
	}, [filters.selectedCarsList]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div
			style={{
				display: "flex",
				flexWrap: "wrap",
				justifyContent: "space-evenly",
				marginTop: "20px",
			}}
		>
			{data.map((item: any, itemIndex: number) => (
				<div style={{ margin: "5px" }}>
					<PieChart
						key={itemIndex}
						slotProps={{
							legend: {
								direction: "row",
								position: { vertical: "top", horizontal: "middle" },
								labelStyle: {
									fontSize: 14,
									fill: `${getChartTextColor(theme)}`,
								},
							},
						}}
						sx={() => ({
							[`.${axisClasses.root}`]: {
								[`.${axisClasses.tick}, .${axisClasses.line}`]: {
									stroke: `${getChartTicklineColor(theme)}`,
									strokeWidth: 3,
								},
								[`.${axisClasses.tickLabel}`]: {
									fill: `${getChartTextColor(theme)}`,
								},
							},
							[`.MuiPieArcLabel-root`]: {
								stroke: "white",
								fontWeight: "100",
								fontSize: "10px",
							},
							[`.MuiPieArc-root`]: {
								border: "none",
								borderStyle: "none",
							},
							borderRadius: "5px",
							backgroundColor: `${getChartBackgroundColor(theme)}`,
						})}
						series={[
							{
								paddingAngle: 5,
								innerRadius: 30,
								outerRadius: 60,
								highlightScope: { highlighted: "item", faded: "global" },
								arcLabel: "value",
								data: item.data,
							},
						]}
						margin={{ right: 5, top: 90 }}
						width={225}
						height={230}
					>
						<PieCenterLabel>{item.label}</PieCenterLabel>
					</PieChart>
				</div>
			))}
		</div>
	);
}

export default ChartPie;
