import React, { useEffect, useState } from "react";
// ANT DESIGN COMPONENTS
import { Tag, Tooltip, Spin, Switch } from "antd";
// REDUX
import { useSelector } from "react-redux";
import { useLazyGetBankMessagesQuery } from "../../../redux/monitoring/monitoringAPI";
// INTERFACES
import { RootState } from "../../../app/store";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";

function ControlPanelStatus({ layout, socketMessage }: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [messages, setMessages] = useState<any>([]);
	const [lastShortMesage, setLastShortMessage] = useState<string>("");
	const [showShortMessage, setShowShortMessage] = useState<boolean>(true);
	const [showIsLoading, setShowIsLoading] = useState<boolean>(false);

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetBankMessages, { data }] = useLazyGetBankMessagesQuery();
	// ************************************************ */
	// FUNCTIONS ************************************** */
	const initData = async () => {
		if (layout.idBank) {
			const token = await GETJwtToken();
			triggerGetBankMessages({
				token,
				bankId: layout.idBank,
			});
		}
	};

	const displayIsLoading = () => {
		setShowIsLoading(true);
		setTimeout(() => {
			setShowIsLoading(false);
		}, 500);
	};

	const onChangeShowShortMessage = (checked: boolean) => {
		displayIsLoading();
		setShowShortMessage(checked);
	};

	// ************************************************ */
	// USE EFFECT ************************************* */
	useEffect(() => {
		if (
			socketMessage &&
			socketMessage.icon &&
			socketMessage.icon.name &&
			socketMessage.icon.name !== lastShortMesage
		) {
			setLastShortMessage(socketMessage.icon.name.toLowerCase());
		}
	}, [socketMessage]);

	useEffect(() => {
		initData();
	}, []);

	useEffect(() => {
		if (data) {
			setMessages(data.data);
		}
	}, [data]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<Spin spinning={messages.length === 0 || showIsLoading}>
			<div
				className='monitoringView__detailsBox'
				style={{ minHeight: "400px", height: "auto" }}
			>
				<div
					className={`montoringView__controlPanel montoringView__controlPanel__${theme}`}
					style={{
						minHeight: "400px",
						height: "400px",
						overflowY: "scroll",
						paddingBottom: "25px",
					}}
				>
					<div className='generalStyles__flex'>
						<div className='generalStyles__mlFix25 mt-3 mb-3'>
							<Switch
								checked={showShortMessage}
								onChange={onChangeShowShortMessage}
							/>
						</div>
						<div className='generalStyles__mlFix mt-3 mb-3 '>
							<span>{showShortMessage ? "Short Messages" : "Messages"}</span>
						</div>
					</div>
					{!showIsLoading && (
						<div className='generalStyles__flexWrap generalStyles__flexCenter'>
							{messages.map((item: any) => (
								<Tooltip
									key={item.id}
									title={item && item.message ? item.message : "--"}
								>
									<Tag
										color={
											lastShortMesage === item.short_message.toLowerCase()
												? "green"
												: ""
										}
										className={`${
											showShortMessage
												? "generalStyles__ellipsis__100px"
												: "generalStyles__ellipsis__250px"
										} generalStyles__textCenter mt-2`}
									>
										{showShortMessage ? (
											<>
												{item && item.short_message
													? item.short_message.toUpperCase()
													: "--"}
											</>
										) : (
											<>
												{item && item.message
													? item.message.toUpperCase()
													: "--"}
											</>
										)}
									</Tag>
								</Tooltip>
							))}
						</div>
					)}
				</div>
			</div>
		</Spin>
	);
}

export default ControlPanelStatus;
