import React from "react";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// ANT DESIGN COMPONENTS
import { Input, Button } from "antd";

function FilterSearchText({
	placeHolder,
	filterValue,
	setFilterValue,
	refreshTable,
}: any) {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const [t] = useTranslation("global");

	// ************************************************ */
	// USE STATE VARIABLES **************************** */

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const onPressEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			refreshTable();
		}
	};

	// ************************************************* */
	// USE EFFECT ************************************** */

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='generalStyles__searchDropdown'>
			<Input
				style={{ marginBottom: 8, display: "block" }}
				placeholder={placeHolder}
				onChange={(e) => {
					setFilterValue(e.target.value);
				}}
				onKeyDown={onPressEnter}
				value={filterValue}
			/>
			<div className='generalStyles__spaceBetween'>
				<div />
				<div>
					<Button
						type='primary'
						style={{ width: 90 }}
						size='small'
						onClick={refreshTable}
					>
						{t("general.search")}
					</Button>
				</div>
				<div>
					<Button
						style={{ width: 90 }}
						size='small'
						onClick={() => {
							setFilterValue(null);
						}}
					>
						{t("general.reset")}
					</Button>
				</div>
			</div>
		</div>
	);
}

export default FilterSearchText;
