import React, { useEffect, useState } from "react";
// REDUX
import { useSelector } from "react-redux";
// COMPONENTS
import MonitoringElevatorsFullView from "../fullView/MonitoringElevatorsFullView";
import MonitoringTopView from "../topView/MonitoringTopView";
import MonitoringEscalatorsFullView from "../fullView/MonitoringEscalatorsFullView";
import MonitoringMovingWalkFullView from "../fullView/MonitoringMovingWalkFullView";
import MonitoringStatusView from "../statusView/MonitoringStatusView";
import MonitoringServiceView from "../serviceView/MonitoringServiceView";
// INTERFACES
import {
	MonitoringBankInterface,
	SocketMessageInterface,
} from "../../../interfaces/EdgeManagement.interface";
import { RootState } from "../../../app/store";

const MonitoringBank: React.FC<MonitoringBankInterface> = ({
	layout,
	socketMessage,
	iconDefinitionElevator,
	iconDefinitionEscalator,
	iconDefinitionMovingwalk,
}) => {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const VALID_MESSAGES_NO_ICON = ["PF", "FAULT"];
	const BLANK_SOCKET_MESSAGE = {
		bank_id: "",
		lift_id: "",
		lift_type: "",
		car_calls: { front: [], rear: [] },
		hall_calls: {
			up: {
				front: [],
				rear: [],
			},
			down: {
				front: [],
				rear: [],
			},
			up_assigned: { front: [], rear: [] },
			down_assigned: { front: [], rear: [] },
		},
		faults: false,
		position: 0,
		icon: {
			type: "",
			name: "",
			description: "",
		},
		service_mode: {
			name: "",
			description: "",
		},
		heartbeat: 0,
	};

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [elevatorSocketMessage, setElevatorSocketMessage] =
		useState<SocketMessageInterface>(BLANK_SOCKET_MESSAGE);
	const [escalatorSocketMessage, setEscalatorSocketMessage] =
		useState<SocketMessageInterface>(BLANK_SOCKET_MESSAGE);
	const [movingWalkSocketMessage, setMovingWalkSocketMessage] =
		useState<SocketMessageInterface>(BLANK_SOCKET_MESSAGE);
	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { monitoringGridType } = useSelector(
		(state: RootState) => state.monitoring
	);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const checkIfSocketMessageIsValid = (obj: SocketMessageInterface) =>
		obj &&
		obj.bank_id &&
		obj.bank_id !== "" &&
		obj.lift_id &&
		obj.lift_id !== "" &&
		obj.lift_type &&
		obj.lift_type !== "" &&
		obj.car_calls &&
		obj.car_calls.front &&
		obj.car_calls.rear &&
		obj.hall_calls &&
		obj.hall_calls.up &&
		obj.hall_calls.up.front &&
		obj.hall_calls.up.rear &&
		obj.hall_calls.down &&
		obj.hall_calls.down.front &&
		obj.hall_calls.down.rear &&
		obj.hall_calls.up_assigned &&
		obj.hall_calls.up_assigned.front &&
		obj.hall_calls.up_assigned.rear &&
		obj.hall_calls.down_assigned &&
		obj.hall_calls.down_assigned.front &&
		obj.hall_calls.down_assigned.rear &&
		obj.position >= 0 &&
		obj.icon &&
		obj.icon.type &&
		obj.icon.name &&
		obj.service_mode;

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		if (
			layout &&
			layout.id &&
			socketMessage &&
			socketMessage.bank_id &&
			layout.id === socketMessage.bank_id
		) {
			if (
				(socketMessage.lift_type === "ELEVATOR" &&
					checkIfSocketMessageIsValid(socketMessage)) ||
				socketMessage.lift_type === "ESCALATOR" ||
				socketMessage.lift_type === "MOVINGWALK"
			) {
				if (
					socketMessage.lift_type === "ELEVATOR" &&
					(iconDefinitionElevator[socketMessage.icon.name.toLowerCase()] ||
						VALID_MESSAGES_NO_ICON.includes(
							socketMessage.icon.name.replace(/[0-9]/g, "").toUpperCase()
						) ||
						socketMessage.icon.type.toUpperCase() === "FAULT" ||
						socketMessage.icon.type.toUpperCase() === "CARSERVICE")
				) {
					setElevatorSocketMessage(socketMessage);
				} else if (
					socketMessage.lift_type === "ESCALATOR" &&
					(iconDefinitionEscalator[socketMessage.icon.name.toLowerCase()] ||
						VALID_MESSAGES_NO_ICON.includes(
							socketMessage.icon.name.replace(/[0-9]/g, "").toUpperCase()
						) ||
						socketMessage.icon.type.toUpperCase() === "FAULT" ||
						socketMessage.icon.type.toUpperCase() === "CARSERVICE")
				) {
					setEscalatorSocketMessage(socketMessage);
				} else if (
					socketMessage.lift_type === "MOVINGWALK" &&
					(iconDefinitionMovingwalk[socketMessage.icon.name.toLowerCase()] ||
						VALID_MESSAGES_NO_ICON.includes(
							socketMessage.icon.name.replace(/[0-9]/g, "").toUpperCase()
						) ||
						socketMessage.icon.type.toUpperCase() === "FAULT" ||
						socketMessage.icon.type.toUpperCase() === "CARSERVICE")
				) {
					setMovingWalkSocketMessage(socketMessage);
				}
			}
		}
	}, [socketMessage]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div className='generalStyles__width100'>
			{/* ************************************************* */}
			{/** TOP VIEW */}
			{monitoringGridType === "TOPVIEW" && (
				<>
					{layout && layout.type === "ELEVATOR" && (
						<MonitoringTopView
							socketMessage={elevatorSocketMessage}
							layout={layout}
							iconDefinitionElevator={iconDefinitionElevator}
							iconDefinitionEscalator={iconDefinitionEscalator}
							iconDefinitionMovingwalk={iconDefinitionMovingwalk}
						/>
					)}
					{layout && layout.type === "ESCALATOR" && (
						<MonitoringEscalatorsFullView
							socketMessage={escalatorSocketMessage}
							layout={layout}
							iconDefinitionEscalator={iconDefinitionEscalator}
						/>
					)}
					{layout && layout.type === "MOVINGWALK" && (
						<MonitoringMovingWalkFullView
							socketMessage={movingWalkSocketMessage}
							layout={layout}
							iconDefinitionMovingwalk={iconDefinitionMovingwalk}
						/>
					)}
				</>
			)}
			{/* ************************************************* */}
			{/** STATUS VIEW */}
			{monitoringGridType === "STATUSVIEW" && (
				<MonitoringStatusView
					socketMessage={elevatorSocketMessage}
					layout={layout}
				/>
			)}
			{/* ************************************************* */}
			{/** SERVICE VIEW */}
			{monitoringGridType === "SERVICEVIEW" && (
				<MonitoringServiceView
					elevatorSocketMessage={elevatorSocketMessage}
					escalatorSocketMessage={escalatorSocketMessage}
					movingWalkSocketMessage={movingWalkSocketMessage}
					layout={layout}
				/>
			)}
			{/* ************************************************* */}
			{/** FULL VIEW */}
			{monitoringGridType === "FULLVIEW" && (
				<>
					{layout && layout.type === "ELEVATOR" && (
						<MonitoringElevatorsFullView
							socketMessage={elevatorSocketMessage}
							layout={layout}
							iconDefinitionElevator={iconDefinitionElevator}
						/>
					)}
					{layout && layout.type === "ESCALATOR" && (
						<MonitoringEscalatorsFullView
							socketMessage={escalatorSocketMessage}
							layout={layout}
							iconDefinitionEscalator={iconDefinitionEscalator}
						/>
					)}
					{layout && layout.type === "MOVINGWALK" && (
						<MonitoringMovingWalkFullView
							socketMessage={movingWalkSocketMessage}
							layout={layout}
							iconDefinitionMovingwalk={iconDefinitionMovingwalk}
						/>
					)}
				</>
			)}
		</div>
	);
};

// Wrap the component with React.memo
const MemoizedMonitoringBank = React.memo(MonitoringBank);

export default MemoizedMonitoringBank;
