import React, { useEffect, useState } from "react";
// NAVIGATION
import { useNavigate, useLocation } from "react-router-dom";
// ANT DESIGN COMPONENTS
import { Table, Tag, Button } from "antd";
// I18N TRANSLATION
import { useTranslation } from "react-i18next";
// FONT AWESOME LIBRYARY AND ICONS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
	useLazyGetAlertsQuery,
	notificationAPI,
} from "../../../redux/notifications/notificationAPI";
// AUTHORIZATION
import GETJwtToken from "../../../redux/authentication/authentication";
// UTILS
import { getTableRowClass } from "../../../utils/utils";
// COMPONENTS
import TableNoDataBox from "../../components/table/TableNoDataBox";
// INTERFACES
import { RootState } from "../../../app/store";

function NotificationAlertTable() {
	// ************************************************ */
	// GLOBAL VARIABLES ******************************* */
	const dispatch = useDispatch();
	const [t] = useTranslation("global");
	const { Column } = Table;
	const navigate = useNavigate();
	const location = useLocation();
	const idBank = new URLSearchParams(location.search).get("idBank");

	const fakeData = [
		{
			id: "91e7191a-3d01-40cf-9858-fdc9125b7b63",
			message: "Fault 1",
			recipients: [
				{
					created_at: "2024-06-20T18:00:16.054373Z",
					updated_at: "2024-06-20T18:00:48.072208Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Action",
					middle_name: "Test",
					offline_psw: "xmoB2xDHoKE=",
					email: "daniel.herrera+actiontest@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
					created_offline_psw: true,
					bank: "West Loch ES1",
					message: "Fault 1",
				},
				{
					created_at: "2024-07-16T02:08:16.435731Z",
					updated_at: "2024-07-16T02:08:16.435731Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "test",
					status: "NEW",
					last_name: "pas",
					middle_name: "offline",
					offline_psw:
						"sZXe3S6DxSAhfBGTx5+gjpxfZSibHme+WGm+0/VLr+FdjXrAAHgYGEnOaMfnLe6h",
					email: "daniel.herrera+encript@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "00000000-0000-0000-0000-000000000000",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "0b438b2f-b3dc-4474-9d24-0d786f528f7c",
					created_offline_psw: true,
					bank: "West Loch ES1",
					message: "Fault 1",
				},
				{
					created_at: "2023-12-18T16:38:35.992509Z",
					updated_at: "2024-04-23T13:39:55.841118Z",
					pref_grid_id: "00000000-0000-0000-0000-000000000000",
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Herrrera",
					middle_name: "Diligent",
					offline_psw: "c30da9265ba0",
					email: "daniel.herrera+diligent@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "c3d6d774-10d3-431d-92ad-b411c83bd41d",
					role_id: "7ef3eb97-8f7d-4ab2-8f60-619f3033d0e2",
					org_id: "907730b5-7960-4dad-abee-124205ae438c",
					id: "0d449764-e18f-4b55-9131-6cd067cd641a",
					created_offline_psw: true,
					bank: "West Loch ES1",
					message: "Fault 1",
				},
			],
			bankId: "01099a8b-d9cf-4e0a-9fee-3ea5e4bd04ca",
			bankName: "West Loch ES1",
		},
		{
			id: "003f7e7c-0cfb-4db8-a35b-b95251726ab3",
			message: "Fault 2",
			recipients: [
				{
					created_at: "2024-06-20T18:00:16.054373Z",
					updated_at: "2024-06-20T18:00:48.072208Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Action",
					middle_name: "Test",
					offline_psw: "xmoB2xDHoKE=",
					email: "daniel.herrera+actiontest@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
					created_offline_psw: true,
					bank: "West Loch ES1",
					message: "Fault 2",
				},
				{
					created_at: "2024-07-16T02:08:16.435731Z",
					updated_at: "2024-07-16T02:08:16.435731Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "test",
					status: "NEW",
					last_name: "pas",
					middle_name: "offline",
					offline_psw:
						"sZXe3S6DxSAhfBGTx5+gjpxfZSibHme+WGm+0/VLr+FdjXrAAHgYGEnOaMfnLe6h",
					email: "daniel.herrera+encript@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "00000000-0000-0000-0000-000000000000",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "0b438b2f-b3dc-4474-9d24-0d786f528f7c",
					created_offline_psw: true,
					bank: "West Loch ES1",
					message: "Fault 2",
				},
			],
			bankId: "01099a8b-d9cf-4e0a-9fee-3ea5e4bd04ca",
			bankName: "West Loch ES1",
		},
		{
			id: "4a0c3d50-4f05-42d1-b9b2-dcb1457d3bc3",
			message: "Fault 3",
			recipients: [
				{
					created_at: "2024-06-20T18:00:16.054373Z",
					updated_at: "2024-06-20T18:00:48.072208Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Action",
					middle_name: "Test",
					offline_psw: "xmoB2xDHoKE=",
					email: "daniel.herrera+actiontest@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
					created_offline_psw: true,
					bank: "West Loch ES1",
					message: "Fault 3",
				},
			],
			bankId: "01099a8b-d9cf-4e0a-9fee-3ea5e4bd04ca",
			bankName: "West Loch ES1",
		},
		{
			id: "84fa25fe-2fc0-4f65-ac75-a87448f082c4",
			message: "Fault 4",
			recipients: [
				{
					created_at: "2024-06-20T18:00:16.054373Z",
					updated_at: "2024-06-20T18:00:48.072208Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Action",
					middle_name: "Test",
					offline_psw: "xmoB2xDHoKE=",
					email: "daniel.herrera+actiontest@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
					created_offline_psw: true,
					bank: "West Loch ES1",
					message: "Fault 4",
				},
				{
					created_at: "2024-07-16T02:08:16.435731Z",
					updated_at: "2024-07-16T02:08:16.435731Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "test",
					status: "NEW",
					last_name: "pas",
					middle_name: "offline",
					offline_psw:
						"sZXe3S6DxSAhfBGTx5+gjpxfZSibHme+WGm+0/VLr+FdjXrAAHgYGEnOaMfnLe6h",
					email: "daniel.herrera+encript@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "00000000-0000-0000-0000-000000000000",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "0b438b2f-b3dc-4474-9d24-0d786f528f7c",
					created_offline_psw: true,
					bank: "West Loch ES1",
					message: "Fault 4",
				},
			],
			bankId: "01099a8b-d9cf-4e0a-9fee-3ea5e4bd04ca",
			bankName: "West Loch ES1",
		},
		{
			id: "5a87c711-1163-4332-8b05-b5fd5ebfa3db",
			message: "Fault 5",
			recipients: [
				{
					created_at: "2024-06-20T18:00:16.054373Z",
					updated_at: "2024-06-20T18:00:48.072208Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Action",
					middle_name: "Test",
					offline_psw: "xmoB2xDHoKE=",
					email: "daniel.herrera+actiontest@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
					created_offline_psw: true,
					bank: "West Loch ES1",
					message: "Fault 5",
				},
			],
			bankId: "01099a8b-d9cf-4e0a-9fee-3ea5e4bd04ca",
			bankName: "West Loch ES1",
		},
		{
			id: "78b36134-be52-47a3-9ebc-cab456639b71",
			message: "Fault 1",
			recipients: [
				{
					created_at: "2024-06-20T18:00:16.054373Z",
					updated_at: "2024-06-20T18:00:48.072208Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Action",
					middle_name: "Test",
					offline_psw: "xmoB2xDHoKE=",
					email: "daniel.herrera+actiontest@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
					created_offline_psw: true,
					bank: "gdxg",
					message: "Fault 1",
				},
			],
			bankId: "012c56c1-259b-4193-b47f-a2ce8b378954",
			bankName: "gdxg",
		},
		{
			id: "92411186-6870-430b-b3f4-1d61c840ba07",
			message: "Fault 2",
			recipients: [
				{
					created_at: "2024-06-20T18:00:16.054373Z",
					updated_at: "2024-06-20T18:00:48.072208Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Action",
					middle_name: "Test",
					offline_psw: "xmoB2xDHoKE=",
					email: "daniel.herrera+actiontest@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
					created_offline_psw: true,
					bank: "gdxg",
					message: "Fault 2",
				},
				{
					created_at: "2024-07-16T02:08:16.435731Z",
					updated_at: "2024-07-16T02:08:16.435731Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "test",
					status: "NEW",
					last_name: "pas",
					middle_name: "offline",
					offline_psw:
						"sZXe3S6DxSAhfBGTx5+gjpxfZSibHme+WGm+0/VLr+FdjXrAAHgYGEnOaMfnLe6h",
					email: "daniel.herrera+encript@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "00000000-0000-0000-0000-000000000000",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "0b438b2f-b3dc-4474-9d24-0d786f528f7c",
					created_offline_psw: true,
					bank: "gdxg",
					message: "Fault 2",
				},
				{
					created_at: "2023-12-18T16:38:35.992509Z",
					updated_at: "2024-04-23T13:39:55.841118Z",
					pref_grid_id: "00000000-0000-0000-0000-000000000000",
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Herrrera",
					middle_name: "Diligent",
					offline_psw: "c30da9265ba0",
					email: "daniel.herrera+diligent@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "c3d6d774-10d3-431d-92ad-b411c83bd41d",
					role_id: "7ef3eb97-8f7d-4ab2-8f60-619f3033d0e2",
					org_id: "907730b5-7960-4dad-abee-124205ae438c",
					id: "0d449764-e18f-4b55-9131-6cd067cd641a",
					created_offline_psw: true,
					bank: "gdxg",
					message: "Fault 2",
				},
				{
					created_at: "2024-02-21T23:19:35.504734Z",
					updated_at: "2024-02-21T23:19:35.696362Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Ebony",
					status: "ACTIVE",
					last_name: "Johnson",
					offline_psw: "fafe1b60c241",
					email: "ebony.johnson1@pennmedicine.upenn.edu",
					pref_theme: "",
					cognito_id: "7f568050-e987-4f77-aa3c-0443324bc7bc",
					role_id: "df28d37c-863a-42b2-89f5-a9c27a20b2a0",
					org_id: "63db9117-99f0-4224-a95c-6d908a096bd2",
					id: "0d6e18c9-a80b-4ee5-becc-104d1592e3d7",
					created_offline_psw: true,
					bank: "gdxg",
					message: "Fault 2",
				},
			],
			bankId: "012c56c1-259b-4193-b47f-a2ce8b378954",
			bankName: "gdxg",
		},
		{
			id: "fd947ed6-4daf-4862-baa3-0c2d4472a07f",
			message: "Fault 3",
			recipients: [
				{
					created_at: "2024-06-20T18:00:16.054373Z",
					updated_at: "2024-06-20T18:00:48.072208Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Action",
					middle_name: "Test",
					offline_psw: "xmoB2xDHoKE=",
					email: "daniel.herrera+actiontest@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
					created_offline_psw: true,
					bank: "gdxg",
					message: "Fault 3",
				},
				{
					created_at: "2024-07-16T02:08:16.435731Z",
					updated_at: "2024-07-16T02:08:16.435731Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "test",
					status: "NEW",
					last_name: "pas",
					middle_name: "offline",
					offline_psw:
						"sZXe3S6DxSAhfBGTx5+gjpxfZSibHme+WGm+0/VLr+FdjXrAAHgYGEnOaMfnLe6h",
					email: "daniel.herrera+encript@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "00000000-0000-0000-0000-000000000000",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "0b438b2f-b3dc-4474-9d24-0d786f528f7c",
					created_offline_psw: true,
					bank: "gdxg",
					message: "Fault 3",
				},
				{
					created_at: "2023-12-18T16:38:35.992509Z",
					updated_at: "2024-04-23T13:39:55.841118Z",
					pref_grid_id: "00000000-0000-0000-0000-000000000000",
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Herrrera",
					middle_name: "Diligent",
					offline_psw: "c30da9265ba0",
					email: "daniel.herrera+diligent@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "c3d6d774-10d3-431d-92ad-b411c83bd41d",
					role_id: "7ef3eb97-8f7d-4ab2-8f60-619f3033d0e2",
					org_id: "907730b5-7960-4dad-abee-124205ae438c",
					id: "0d449764-e18f-4b55-9131-6cd067cd641a",
					created_offline_psw: true,
					bank: "gdxg",
					message: "Fault 3",
				},
			],
			bankId: "012c56c1-259b-4193-b47f-a2ce8b378954",
			bankName: "gdxg",
		},
		{
			id: "7434c050-adb3-41f7-903a-11b7ac6149f4",
			message: "Fault 4",
			recipients: [
				{
					created_at: "2024-06-20T18:00:16.054373Z",
					updated_at: "2024-06-20T18:00:48.072208Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Action",
					middle_name: "Test",
					offline_psw: "xmoB2xDHoKE=",
					email: "daniel.herrera+actiontest@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
					created_offline_psw: true,
					bank: "gdxg",
					message: "Fault 4",
				},
				{
					created_at: "2024-07-16T02:08:16.435731Z",
					updated_at: "2024-07-16T02:08:16.435731Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "test",
					status: "NEW",
					last_name: "pas",
					middle_name: "offline",
					offline_psw:
						"sZXe3S6DxSAhfBGTx5+gjpxfZSibHme+WGm+0/VLr+FdjXrAAHgYGEnOaMfnLe6h",
					email: "daniel.herrera+encript@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "00000000-0000-0000-0000-000000000000",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "0b438b2f-b3dc-4474-9d24-0d786f528f7c",
					created_offline_psw: true,
					bank: "gdxg",
					message: "Fault 4",
				},
				{
					created_at: "2023-12-18T16:38:35.992509Z",
					updated_at: "2024-04-23T13:39:55.841118Z",
					pref_grid_id: "00000000-0000-0000-0000-000000000000",
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Herrrera",
					middle_name: "Diligent",
					offline_psw: "c30da9265ba0",
					email: "daniel.herrera+diligent@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "c3d6d774-10d3-431d-92ad-b411c83bd41d",
					role_id: "7ef3eb97-8f7d-4ab2-8f60-619f3033d0e2",
					org_id: "907730b5-7960-4dad-abee-124205ae438c",
					id: "0d449764-e18f-4b55-9131-6cd067cd641a",
					created_offline_psw: true,
					bank: "gdxg",
					message: "Fault 4",
				},
			],
			bankId: "012c56c1-259b-4193-b47f-a2ce8b378954",
			bankName: "gdxg",
		},
		{
			id: "f9001f88-1dff-4888-9fdb-af5853015fe4",
			message: "Fault 5",
			recipients: [
				{
					created_at: "2024-06-20T18:00:16.054373Z",
					updated_at: "2024-06-20T18:00:48.072208Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Action",
					middle_name: "Test",
					offline_psw: "xmoB2xDHoKE=",
					email: "daniel.herrera+actiontest@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
					created_offline_psw: true,
					bank: "gdxg",
					message: "Fault 5",
				},
			],
			bankId: "012c56c1-259b-4193-b47f-a2ce8b378954",
			bankName: "gdxg",
		},
		{
			id: "b11ca651-ac13-454a-a8d4-9365f8332d91",
			message: "Fault 6",
			recipients: [
				{
					created_at: "2024-06-20T18:00:16.054373Z",
					updated_at: "2024-06-20T18:00:48.072208Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Action",
					middle_name: "Test",
					offline_psw: "xmoB2xDHoKE=",
					email: "daniel.herrera+actiontest@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
					created_offline_psw: true,
					bank: "gdxg",
					message: "Fault 6",
				},
				{
					created_at: "2024-07-16T02:08:16.435731Z",
					updated_at: "2024-07-16T02:08:16.435731Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "test",
					status: "NEW",
					last_name: "pas",
					middle_name: "offline",
					offline_psw:
						"sZXe3S6DxSAhfBGTx5+gjpxfZSibHme+WGm+0/VLr+FdjXrAAHgYGEnOaMfnLe6h",
					email: "daniel.herrera+encript@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "00000000-0000-0000-0000-000000000000",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "0b438b2f-b3dc-4474-9d24-0d786f528f7c",
					created_offline_psw: true,
					bank: "gdxg",
					message: "Fault 6",
				},
				{
					created_at: "2023-12-18T16:38:35.992509Z",
					updated_at: "2024-04-23T13:39:55.841118Z",
					pref_grid_id: "00000000-0000-0000-0000-000000000000",
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Herrrera",
					middle_name: "Diligent",
					offline_psw: "c30da9265ba0",
					email: "daniel.herrera+diligent@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "c3d6d774-10d3-431d-92ad-b411c83bd41d",
					role_id: "7ef3eb97-8f7d-4ab2-8f60-619f3033d0e2",
					org_id: "907730b5-7960-4dad-abee-124205ae438c",
					id: "0d449764-e18f-4b55-9131-6cd067cd641a",
					created_offline_psw: true,
					bank: "gdxg",
					message: "Fault 6",
				},
			],
			bankId: "012c56c1-259b-4193-b47f-a2ce8b378954",
			bankName: "gdxg",
		},
		{
			id: "fda8e07c-8197-4395-b6ed-2f52318a4b67",
			message: "Fault 1",
			recipients: [
				{
					created_at: "2024-06-20T18:00:16.054373Z",
					updated_at: "2024-06-20T18:00:48.072208Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Action",
					middle_name: "Test",
					offline_psw: "xmoB2xDHoKE=",
					email: "daniel.herrera+actiontest@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
					created_offline_psw: true,
					bank: "Pearl Ridge EL2",
					message: "Fault 1",
				},
				{
					created_at: "2024-07-16T02:08:16.435731Z",
					updated_at: "2024-07-16T02:08:16.435731Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "test",
					status: "NEW",
					last_name: "pas",
					middle_name: "offline",
					offline_psw:
						"sZXe3S6DxSAhfBGTx5+gjpxfZSibHme+WGm+0/VLr+FdjXrAAHgYGEnOaMfnLe6h",
					email: "daniel.herrera+encript@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "00000000-0000-0000-0000-000000000000",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "0b438b2f-b3dc-4474-9d24-0d786f528f7c",
					created_offline_psw: true,
					bank: "Pearl Ridge EL2",
					message: "Fault 1",
				},
			],
			bankId: "045908a0-d5c7-4b18-981a-2c4307e272e0",
			bankName: "Pearl Ridge EL2",
		},
		{
			id: "70239481-4b56-4c6c-b821-7aab14c4bb03",
			message: "Fault 2",
			recipients: [
				{
					created_at: "2024-06-20T18:00:16.054373Z",
					updated_at: "2024-06-20T18:00:48.072208Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Action",
					middle_name: "Test",
					offline_psw: "xmoB2xDHoKE=",
					email: "daniel.herrera+actiontest@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
					created_offline_psw: true,
					bank: "Pearl Ridge EL2",
					message: "Fault 2",
				},
				{
					created_at: "2024-07-16T02:08:16.435731Z",
					updated_at: "2024-07-16T02:08:16.435731Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "test",
					status: "NEW",
					last_name: "pas",
					middle_name: "offline",
					offline_psw:
						"sZXe3S6DxSAhfBGTx5+gjpxfZSibHme+WGm+0/VLr+FdjXrAAHgYGEnOaMfnLe6h",
					email: "daniel.herrera+encript@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "00000000-0000-0000-0000-000000000000",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "0b438b2f-b3dc-4474-9d24-0d786f528f7c",
					created_offline_psw: true,
					bank: "Pearl Ridge EL2",
					message: "Fault 2",
				},
			],
			bankId: "045908a0-d5c7-4b18-981a-2c4307e272e0",
			bankName: "Pearl Ridge EL2",
		},
		{
			id: "3e874de8-3aa5-46e2-aaaa-d8e5ec6702e5",
			message: "Fault 3",
			recipients: [
				{
					created_at: "2024-06-20T18:00:16.054373Z",
					updated_at: "2024-06-20T18:00:48.072208Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Action",
					middle_name: "Test",
					offline_psw: "xmoB2xDHoKE=",
					email: "daniel.herrera+actiontest@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
					created_offline_psw: true,
					bank: "Pearl Ridge EL2",
					message: "Fault 3",
				},
				{
					created_at: "2024-07-16T02:08:16.435731Z",
					updated_at: "2024-07-16T02:08:16.435731Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "test",
					status: "NEW",
					last_name: "pas",
					middle_name: "offline",
					offline_psw:
						"sZXe3S6DxSAhfBGTx5+gjpxfZSibHme+WGm+0/VLr+FdjXrAAHgYGEnOaMfnLe6h",
					email: "daniel.herrera+encript@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "00000000-0000-0000-0000-000000000000",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "0b438b2f-b3dc-4474-9d24-0d786f528f7c",
					created_offline_psw: true,
					bank: "Pearl Ridge EL2",
					message: "Fault 3",
				},
			],
			bankId: "045908a0-d5c7-4b18-981a-2c4307e272e0",
			bankName: "Pearl Ridge EL2",
		},
		{
			id: "54acce48-bd27-4fc5-a984-ea657c08daec",
			message: "Fault 4",
			recipients: [
				{
					created_at: "2024-06-20T18:00:16.054373Z",
					updated_at: "2024-06-20T18:00:48.072208Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Action",
					middle_name: "Test",
					offline_psw: "xmoB2xDHoKE=",
					email: "daniel.herrera+actiontest@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
					created_offline_psw: true,
					bank: "Pearl Ridge EL2",
					message: "Fault 4",
				},
				{
					created_at: "2024-07-16T02:08:16.435731Z",
					updated_at: "2024-07-16T02:08:16.435731Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "test",
					status: "NEW",
					last_name: "pas",
					middle_name: "offline",
					offline_psw:
						"sZXe3S6DxSAhfBGTx5+gjpxfZSibHme+WGm+0/VLr+FdjXrAAHgYGEnOaMfnLe6h",
					email: "daniel.herrera+encript@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "00000000-0000-0000-0000-000000000000",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "0b438b2f-b3dc-4474-9d24-0d786f528f7c",
					created_offline_psw: true,
					bank: "Pearl Ridge EL2",
					message: "Fault 4",
				},
			],
			bankId: "045908a0-d5c7-4b18-981a-2c4307e272e0",
			bankName: "Pearl Ridge EL2",
		},
		{
			id: "d5412a6d-93b6-4cff-99a9-872ab00fb495",
			message: "Fault 5",
			recipients: [
				{
					created_at: "2024-06-20T18:00:16.054373Z",
					updated_at: "2024-06-20T18:00:48.072208Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Action",
					middle_name: "Test",
					offline_psw: "xmoB2xDHoKE=",
					email: "daniel.herrera+actiontest@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
					created_offline_psw: true,
					bank: "Pearl Ridge EL2",
					message: "Fault 5",
				},
				{
					created_at: "2024-07-16T02:08:16.435731Z",
					updated_at: "2024-07-16T02:08:16.435731Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "test",
					status: "NEW",
					last_name: "pas",
					middle_name: "offline",
					offline_psw:
						"sZXe3S6DxSAhfBGTx5+gjpxfZSibHme+WGm+0/VLr+FdjXrAAHgYGEnOaMfnLe6h",
					email: "daniel.herrera+encript@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "00000000-0000-0000-0000-000000000000",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "0b438b2f-b3dc-4474-9d24-0d786f528f7c",
					created_offline_psw: true,
					bank: "Pearl Ridge EL2",
					message: "Fault 5",
				},
				{
					created_at: "2023-12-18T16:38:35.992509Z",
					updated_at: "2024-04-23T13:39:55.841118Z",
					pref_grid_id: "00000000-0000-0000-0000-000000000000",
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Herrrera",
					middle_name: "Diligent",
					offline_psw: "c30da9265ba0",
					email: "daniel.herrera+diligent@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "c3d6d774-10d3-431d-92ad-b411c83bd41d",
					role_id: "7ef3eb97-8f7d-4ab2-8f60-619f3033d0e2",
					org_id: "907730b5-7960-4dad-abee-124205ae438c",
					id: "0d449764-e18f-4b55-9131-6cd067cd641a",
					created_offline_psw: true,
					bank: "Pearl Ridge EL2",
					message: "Fault 5",
				},
				{
					created_at: "2024-02-21T23:19:35.504734Z",
					updated_at: "2024-02-21T23:19:35.696362Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Ebony",
					status: "ACTIVE",
					last_name: "Johnson",
					offline_psw: "fafe1b60c241",
					email: "ebony.johnson1@pennmedicine.upenn.edu",
					pref_theme: "",
					cognito_id: "7f568050-e987-4f77-aa3c-0443324bc7bc",
					role_id: "df28d37c-863a-42b2-89f5-a9c27a20b2a0",
					org_id: "63db9117-99f0-4224-a95c-6d908a096bd2",
					id: "0d6e18c9-a80b-4ee5-becc-104d1592e3d7",
					created_offline_psw: true,
					bank: "Pearl Ridge EL2",
					message: "Fault 5",
				},
			],
			bankId: "045908a0-d5c7-4b18-981a-2c4307e272e0",
			bankName: "Pearl Ridge EL2",
		},
		{
			id: "fe1e1bc3-7979-4705-9ec5-d34b31997dde",
			message: "Fault 6",
			recipients: [
				{
					created_at: "2024-06-20T18:00:16.054373Z",
					updated_at: "2024-06-20T18:00:48.072208Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Action",
					middle_name: "Test",
					offline_psw: "xmoB2xDHoKE=",
					email: "daniel.herrera+actiontest@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
					created_offline_psw: true,
					bank: "Pearl Ridge EL2",
					message: "Fault 6",
				},
				{
					created_at: "2024-07-16T02:08:16.435731Z",
					updated_at: "2024-07-16T02:08:16.435731Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "test",
					status: "NEW",
					last_name: "pas",
					middle_name: "offline",
					offline_psw:
						"sZXe3S6DxSAhfBGTx5+gjpxfZSibHme+WGm+0/VLr+FdjXrAAHgYGEnOaMfnLe6h",
					email: "daniel.herrera+encript@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "00000000-0000-0000-0000-000000000000",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "0b438b2f-b3dc-4474-9d24-0d786f528f7c",
					created_offline_psw: true,
					bank: "Pearl Ridge EL2",
					message: "Fault 6",
				},
			],
			bankId: "045908a0-d5c7-4b18-981a-2c4307e272e0",
			bankName: "Pearl Ridge EL2",
		},
		{
			id: "44fd65f7-fcef-47b5-9c18-97fbefc25bb7",
			message: "Fault 7",
			recipients: [
				{
					created_at: "2024-06-20T18:00:16.054373Z",
					updated_at: "2024-06-20T18:00:48.072208Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Action",
					middle_name: "Test",
					offline_psw: "xmoB2xDHoKE=",
					email: "daniel.herrera+actiontest@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
					created_offline_psw: true,
					bank: "Pearl Ridge EL2",
					message: "Fault 7",
				},
				{
					created_at: "2024-07-16T02:08:16.435731Z",
					updated_at: "2024-07-16T02:08:16.435731Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "test",
					status: "NEW",
					last_name: "pas",
					middle_name: "offline",
					offline_psw:
						"sZXe3S6DxSAhfBGTx5+gjpxfZSibHme+WGm+0/VLr+FdjXrAAHgYGEnOaMfnLe6h",
					email: "daniel.herrera+encript@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "00000000-0000-0000-0000-000000000000",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "0b438b2f-b3dc-4474-9d24-0d786f528f7c",
					created_offline_psw: true,
					bank: "Pearl Ridge EL2",
					message: "Fault 7",
				},
			],
			bankId: "045908a0-d5c7-4b18-981a-2c4307e272e0",
			bankName: "Pearl Ridge EL2",
		},
		{
			id: "b752d2ce-06a6-4bae-b04b-5951171716b3",
			message: "Fault 1",
			recipients: [
				{
					created_at: "2024-06-20T18:00:16.054373Z",
					updated_at: "2024-06-20T18:00:48.072208Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Action",
					middle_name: "Test",
					offline_psw: "xmoB2xDHoKE=",
					email: "daniel.herrera+actiontest@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
					created_offline_psw: true,
					bank: "Garage",
					message: "Fault 1",
				},
				{
					created_at: "2024-07-16T02:08:16.435731Z",
					updated_at: "2024-07-16T02:08:16.435731Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "test",
					status: "NEW",
					last_name: "pas",
					middle_name: "offline",
					offline_psw:
						"sZXe3S6DxSAhfBGTx5+gjpxfZSibHme+WGm+0/VLr+FdjXrAAHgYGEnOaMfnLe6h",
					email: "daniel.herrera+encript@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "00000000-0000-0000-0000-000000000000",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "0b438b2f-b3dc-4474-9d24-0d786f528f7c",
					created_offline_psw: true,
					bank: "Garage",
					message: "Fault 1",
				},
				{
					created_at: "2023-12-18T16:38:35.992509Z",
					updated_at: "2024-04-23T13:39:55.841118Z",
					pref_grid_id: "00000000-0000-0000-0000-000000000000",
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Herrrera",
					middle_name: "Diligent",
					offline_psw: "c30da9265ba0",
					email: "daniel.herrera+diligent@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "c3d6d774-10d3-431d-92ad-b411c83bd41d",
					role_id: "7ef3eb97-8f7d-4ab2-8f60-619f3033d0e2",
					org_id: "907730b5-7960-4dad-abee-124205ae438c",
					id: "0d449764-e18f-4b55-9131-6cd067cd641a",
					created_offline_psw: true,
					bank: "Garage",
					message: "Fault 1",
				},
				{
					created_at: "2024-02-21T23:19:35.504734Z",
					updated_at: "2024-02-21T23:19:35.696362Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Ebony",
					status: "ACTIVE",
					last_name: "Johnson",
					offline_psw: "fafe1b60c241",
					email: "ebony.johnson1@pennmedicine.upenn.edu",
					pref_theme: "",
					cognito_id: "7f568050-e987-4f77-aa3c-0443324bc7bc",
					role_id: "df28d37c-863a-42b2-89f5-a9c27a20b2a0",
					org_id: "63db9117-99f0-4224-a95c-6d908a096bd2",
					id: "0d6e18c9-a80b-4ee5-becc-104d1592e3d7",
					created_offline_psw: true,
					bank: "Garage",
					message: "Fault 1",
				},
			],
			bankId: "0acf391d-a972-4352-94f2-e5972e005825",
			bankName: "Garage",
		},
		{
			id: "7e334b25-2f85-43cb-a76e-4828a2f4099d",
			message: "Fault 2",
			recipients: [
				{
					created_at: "2024-06-20T18:00:16.054373Z",
					updated_at: "2024-06-20T18:00:48.072208Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Action",
					middle_name: "Test",
					offline_psw: "xmoB2xDHoKE=",
					email: "daniel.herrera+actiontest@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
					created_offline_psw: true,
					bank: "Garage",
					message: "Fault 2",
				},
			],
			bankId: "0acf391d-a972-4352-94f2-e5972e005825",
			bankName: "Garage",
		},
		{
			id: "98eea399-ceab-40a5-95b5-0acbc03c26f8",
			message: "Fault 3",
			recipients: [
				{
					created_at: "2024-06-20T18:00:16.054373Z",
					updated_at: "2024-06-20T18:00:48.072208Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Action",
					middle_name: "Test",
					offline_psw: "xmoB2xDHoKE=",
					email: "daniel.herrera+actiontest@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
					created_offline_psw: true,
					bank: "Garage",
					message: "Fault 3",
				},
			],
			bankId: "0acf391d-a972-4352-94f2-e5972e005825",
			bankName: "Garage",
		},
		{
			id: "650181fb-39fe-4f88-97b4-cf31465fa3e5",
			message: "Fault 4",
			recipients: [
				{
					created_at: "2024-06-20T18:00:16.054373Z",
					updated_at: "2024-06-20T18:00:48.072208Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Action",
					middle_name: "Test",
					offline_psw: "xmoB2xDHoKE=",
					email: "daniel.herrera+actiontest@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
					created_offline_psw: true,
					bank: "Garage",
					message: "Fault 4",
				},
				{
					created_at: "2024-07-16T02:08:16.435731Z",
					updated_at: "2024-07-16T02:08:16.435731Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "test",
					status: "NEW",
					last_name: "pas",
					middle_name: "offline",
					offline_psw:
						"sZXe3S6DxSAhfBGTx5+gjpxfZSibHme+WGm+0/VLr+FdjXrAAHgYGEnOaMfnLe6h",
					email: "daniel.herrera+encript@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "00000000-0000-0000-0000-000000000000",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "0b438b2f-b3dc-4474-9d24-0d786f528f7c",
					created_offline_psw: true,
					bank: "Garage",
					message: "Fault 4",
				},
				{
					created_at: "2023-12-18T16:38:35.992509Z",
					updated_at: "2024-04-23T13:39:55.841118Z",
					pref_grid_id: "00000000-0000-0000-0000-000000000000",
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Herrrera",
					middle_name: "Diligent",
					offline_psw: "c30da9265ba0",
					email: "daniel.herrera+diligent@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "c3d6d774-10d3-431d-92ad-b411c83bd41d",
					role_id: "7ef3eb97-8f7d-4ab2-8f60-619f3033d0e2",
					org_id: "907730b5-7960-4dad-abee-124205ae438c",
					id: "0d449764-e18f-4b55-9131-6cd067cd641a",
					created_offline_psw: true,
					bank: "Garage",
					message: "Fault 4",
				},
			],
			bankId: "0acf391d-a972-4352-94f2-e5972e005825",
			bankName: "Garage",
		},
		{
			id: "ef8c9062-0aad-4b28-b622-f50ccbbcb716",
			message: "Fault 5",
			recipients: [
				{
					created_at: "2024-06-20T18:00:16.054373Z",
					updated_at: "2024-06-20T18:00:48.072208Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Action",
					middle_name: "Test",
					offline_psw: "xmoB2xDHoKE=",
					email: "daniel.herrera+actiontest@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
					created_offline_psw: true,
					bank: "Garage",
					message: "Fault 5",
				},
				{
					created_at: "2024-07-16T02:08:16.435731Z",
					updated_at: "2024-07-16T02:08:16.435731Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "test",
					status: "NEW",
					last_name: "pas",
					middle_name: "offline",
					offline_psw:
						"sZXe3S6DxSAhfBGTx5+gjpxfZSibHme+WGm+0/VLr+FdjXrAAHgYGEnOaMfnLe6h",
					email: "daniel.herrera+encript@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "00000000-0000-0000-0000-000000000000",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "0b438b2f-b3dc-4474-9d24-0d786f528f7c",
					created_offline_psw: true,
					bank: "Garage",
					message: "Fault 5",
				},
				{
					created_at: "2023-12-18T16:38:35.992509Z",
					updated_at: "2024-04-23T13:39:55.841118Z",
					pref_grid_id: "00000000-0000-0000-0000-000000000000",
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Herrrera",
					middle_name: "Diligent",
					offline_psw: "c30da9265ba0",
					email: "daniel.herrera+diligent@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "c3d6d774-10d3-431d-92ad-b411c83bd41d",
					role_id: "7ef3eb97-8f7d-4ab2-8f60-619f3033d0e2",
					org_id: "907730b5-7960-4dad-abee-124205ae438c",
					id: "0d449764-e18f-4b55-9131-6cd067cd641a",
					created_offline_psw: true,
					bank: "Garage",
					message: "Fault 5",
				},
			],
			bankId: "0acf391d-a972-4352-94f2-e5972e005825",
			bankName: "Garage",
		},
		{
			id: "bf08c6db-4d7c-4eb3-881c-5a490f175ba5",
			message: "Fault 6",
			recipients: [
				{
					created_at: "2024-06-20T18:00:16.054373Z",
					updated_at: "2024-06-20T18:00:48.072208Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Action",
					middle_name: "Test",
					offline_psw: "xmoB2xDHoKE=",
					email: "daniel.herrera+actiontest@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
					created_offline_psw: true,
					bank: "Garage",
					message: "Fault 6",
				},
			],
			bankId: "0acf391d-a972-4352-94f2-e5972e005825",
			bankName: "Garage",
		},
		{
			id: "af035ad7-142f-4615-8f01-7f70f9bacbcd",
			message: "Fault 7",
			recipients: [
				{
					created_at: "2024-06-20T18:00:16.054373Z",
					updated_at: "2024-06-20T18:00:48.072208Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Action",
					middle_name: "Test",
					offline_psw: "xmoB2xDHoKE=",
					email: "daniel.herrera+actiontest@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
					created_offline_psw: true,
					bank: "Garage",
					message: "Fault 7",
				},
				{
					created_at: "2024-07-16T02:08:16.435731Z",
					updated_at: "2024-07-16T02:08:16.435731Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "test",
					status: "NEW",
					last_name: "pas",
					middle_name: "offline",
					offline_psw:
						"sZXe3S6DxSAhfBGTx5+gjpxfZSibHme+WGm+0/VLr+FdjXrAAHgYGEnOaMfnLe6h",
					email: "daniel.herrera+encript@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "00000000-0000-0000-0000-000000000000",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "0b438b2f-b3dc-4474-9d24-0d786f528f7c",
					created_offline_psw: true,
					bank: "Garage",
					message: "Fault 7",
				},
			],
			bankId: "0acf391d-a972-4352-94f2-e5972e005825",
			bankName: "Garage",
		},
		{
			id: "63f2f4f6-8b1c-4249-9e5c-157c87bbe001",
			message: "Fault 8",
			recipients: [
				{
					created_at: "2024-06-20T18:00:16.054373Z",
					updated_at: "2024-06-20T18:00:48.072208Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Action",
					middle_name: "Test",
					offline_psw: "xmoB2xDHoKE=",
					email: "daniel.herrera+actiontest@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
					created_offline_psw: true,
					bank: "Garage",
					message: "Fault 8",
				},
				{
					created_at: "2024-07-16T02:08:16.435731Z",
					updated_at: "2024-07-16T02:08:16.435731Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "test",
					status: "NEW",
					last_name: "pas",
					middle_name: "offline",
					offline_psw:
						"sZXe3S6DxSAhfBGTx5+gjpxfZSibHme+WGm+0/VLr+FdjXrAAHgYGEnOaMfnLe6h",
					email: "daniel.herrera+encript@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "00000000-0000-0000-0000-000000000000",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "0b438b2f-b3dc-4474-9d24-0d786f528f7c",
					created_offline_psw: true,
					bank: "Garage",
					message: "Fault 8",
				},
				{
					created_at: "2023-12-18T16:38:35.992509Z",
					updated_at: "2024-04-23T13:39:55.841118Z",
					pref_grid_id: "00000000-0000-0000-0000-000000000000",
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Herrrera",
					middle_name: "Diligent",
					offline_psw: "c30da9265ba0",
					email: "daniel.herrera+diligent@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "c3d6d774-10d3-431d-92ad-b411c83bd41d",
					role_id: "7ef3eb97-8f7d-4ab2-8f60-619f3033d0e2",
					org_id: "907730b5-7960-4dad-abee-124205ae438c",
					id: "0d449764-e18f-4b55-9131-6cd067cd641a",
					created_offline_psw: true,
					bank: "Garage",
					message: "Fault 8",
				},
				{
					created_at: "2024-02-21T23:19:35.504734Z",
					updated_at: "2024-02-21T23:19:35.696362Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Ebony",
					status: "ACTIVE",
					last_name: "Johnson",
					offline_psw: "fafe1b60c241",
					email: "ebony.johnson1@pennmedicine.upenn.edu",
					pref_theme: "",
					cognito_id: "7f568050-e987-4f77-aa3c-0443324bc7bc",
					role_id: "df28d37c-863a-42b2-89f5-a9c27a20b2a0",
					org_id: "63db9117-99f0-4224-a95c-6d908a096bd2",
					id: "0d6e18c9-a80b-4ee5-becc-104d1592e3d7",
					created_offline_psw: true,
					bank: "Garage",
					message: "Fault 8",
				},
			],
			bankId: "0acf391d-a972-4352-94f2-e5972e005825",
			bankName: "Garage",
		},
		{
			id: "e193791e-f5ed-4f52-8306-79efc6d1a8d3",
			message: "Fault 1",
			recipients: [
				{
					created_at: "2024-06-20T18:00:16.054373Z",
					updated_at: "2024-06-20T18:00:48.072208Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Action",
					middle_name: "Test",
					offline_psw: "xmoB2xDHoKE=",
					email: "daniel.herrera+actiontest@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
					created_offline_psw: true,
					bank: "Cars 5-6",
					message: "Fault 1",
				},
			],
			bankId: "0cdc9370-5096-455e-9039-a8e799054653",
			bankName: "Cars 5-6",
		},
		{
			id: "30bf5fda-9c9f-43ee-9fda-ae5dff57ff6b",
			message: "Fault 2",
			recipients: [
				{
					created_at: "2024-06-20T18:00:16.054373Z",
					updated_at: "2024-06-20T18:00:48.072208Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Action",
					middle_name: "Test",
					offline_psw: "xmoB2xDHoKE=",
					email: "daniel.herrera+actiontest@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
					created_offline_psw: true,
					bank: "Cars 5-6",
					message: "Fault 2",
				},
				{
					created_at: "2024-07-16T02:08:16.435731Z",
					updated_at: "2024-07-16T02:08:16.435731Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "test",
					status: "NEW",
					last_name: "pas",
					middle_name: "offline",
					offline_psw:
						"sZXe3S6DxSAhfBGTx5+gjpxfZSibHme+WGm+0/VLr+FdjXrAAHgYGEnOaMfnLe6h",
					email: "daniel.herrera+encript@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "00000000-0000-0000-0000-000000000000",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "0b438b2f-b3dc-4474-9d24-0d786f528f7c",
					created_offline_psw: true,
					bank: "Cars 5-6",
					message: "Fault 2",
				},
			],
			bankId: "0cdc9370-5096-455e-9039-a8e799054653",
			bankName: "Cars 5-6",
		},
		{
			id: "e8547dd9-3151-41b6-80c2-bb69520933ca",
			message: "Fault 3",
			recipients: [
				{
					created_at: "2024-06-20T18:00:16.054373Z",
					updated_at: "2024-06-20T18:00:48.072208Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Action",
					middle_name: "Test",
					offline_psw: "xmoB2xDHoKE=",
					email: "daniel.herrera+actiontest@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
					created_offline_psw: true,
					bank: "Cars 5-6",
					message: "Fault 3",
				},
			],
			bankId: "0cdc9370-5096-455e-9039-a8e799054653",
			bankName: "Cars 5-6",
		},
		{
			id: "3afe01a6-da8e-40a8-813a-3dfc884a77b5",
			message: "Fault 4",
			recipients: [
				{
					created_at: "2024-06-20T18:00:16.054373Z",
					updated_at: "2024-06-20T18:00:48.072208Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Action",
					middle_name: "Test",
					offline_psw: "xmoB2xDHoKE=",
					email: "daniel.herrera+actiontest@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
					created_offline_psw: true,
					bank: "Cars 5-6",
					message: "Fault 4",
				},
				{
					created_at: "2024-07-16T02:08:16.435731Z",
					updated_at: "2024-07-16T02:08:16.435731Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "test",
					status: "NEW",
					last_name: "pas",
					middle_name: "offline",
					offline_psw:
						"sZXe3S6DxSAhfBGTx5+gjpxfZSibHme+WGm+0/VLr+FdjXrAAHgYGEnOaMfnLe6h",
					email: "daniel.herrera+encript@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "00000000-0000-0000-0000-000000000000",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "0b438b2f-b3dc-4474-9d24-0d786f528f7c",
					created_offline_psw: true,
					bank: "Cars 5-6",
					message: "Fault 4",
				},
				{
					created_at: "2023-12-18T16:38:35.992509Z",
					updated_at: "2024-04-23T13:39:55.841118Z",
					pref_grid_id: "00000000-0000-0000-0000-000000000000",
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Herrrera",
					middle_name: "Diligent",
					offline_psw: "c30da9265ba0",
					email: "daniel.herrera+diligent@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "c3d6d774-10d3-431d-92ad-b411c83bd41d",
					role_id: "7ef3eb97-8f7d-4ab2-8f60-619f3033d0e2",
					org_id: "907730b5-7960-4dad-abee-124205ae438c",
					id: "0d449764-e18f-4b55-9131-6cd067cd641a",
					created_offline_psw: true,
					bank: "Cars 5-6",
					message: "Fault 4",
				},
			],
			bankId: "0cdc9370-5096-455e-9039-a8e799054653",
			bankName: "Cars 5-6",
		},
		{
			id: "e7d614bf-6c60-4638-a7e0-f46311ef8544",
			message: "Fault 5",
			recipients: [
				{
					created_at: "2024-06-20T18:00:16.054373Z",
					updated_at: "2024-06-20T18:00:48.072208Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Action",
					middle_name: "Test",
					offline_psw: "xmoB2xDHoKE=",
					email: "daniel.herrera+actiontest@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
					created_offline_psw: true,
					bank: "Cars 5-6",
					message: "Fault 5",
				},
				{
					created_at: "2024-07-16T02:08:16.435731Z",
					updated_at: "2024-07-16T02:08:16.435731Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "test",
					status: "NEW",
					last_name: "pas",
					middle_name: "offline",
					offline_psw:
						"sZXe3S6DxSAhfBGTx5+gjpxfZSibHme+WGm+0/VLr+FdjXrAAHgYGEnOaMfnLe6h",
					email: "daniel.herrera+encript@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "00000000-0000-0000-0000-000000000000",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "0b438b2f-b3dc-4474-9d24-0d786f528f7c",
					created_offline_psw: true,
					bank: "Cars 5-6",
					message: "Fault 5",
				},
				{
					created_at: "2023-12-18T16:38:35.992509Z",
					updated_at: "2024-04-23T13:39:55.841118Z",
					pref_grid_id: "00000000-0000-0000-0000-000000000000",
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Herrrera",
					middle_name: "Diligent",
					offline_psw: "c30da9265ba0",
					email: "daniel.herrera+diligent@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "c3d6d774-10d3-431d-92ad-b411c83bd41d",
					role_id: "7ef3eb97-8f7d-4ab2-8f60-619f3033d0e2",
					org_id: "907730b5-7960-4dad-abee-124205ae438c",
					id: "0d449764-e18f-4b55-9131-6cd067cd641a",
					created_offline_psw: true,
					bank: "Cars 5-6",
					message: "Fault 5",
				},
				{
					created_at: "2024-02-21T23:19:35.504734Z",
					updated_at: "2024-02-21T23:19:35.696362Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Ebony",
					status: "ACTIVE",
					last_name: "Johnson",
					offline_psw: "fafe1b60c241",
					email: "ebony.johnson1@pennmedicine.upenn.edu",
					pref_theme: "",
					cognito_id: "7f568050-e987-4f77-aa3c-0443324bc7bc",
					role_id: "df28d37c-863a-42b2-89f5-a9c27a20b2a0",
					org_id: "63db9117-99f0-4224-a95c-6d908a096bd2",
					id: "0d6e18c9-a80b-4ee5-becc-104d1592e3d7",
					created_offline_psw: true,
					bank: "Cars 5-6",
					message: "Fault 5",
				},
			],
			bankId: "0cdc9370-5096-455e-9039-a8e799054653",
			bankName: "Cars 5-6",
		},
		{
			id: "83c96873-83df-4eba-96b4-863c33934d8a",
			message: "Fault 6",
			recipients: [
				{
					created_at: "2024-06-20T18:00:16.054373Z",
					updated_at: "2024-06-20T18:00:48.072208Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Action",
					middle_name: "Test",
					offline_psw: "xmoB2xDHoKE=",
					email: "daniel.herrera+actiontest@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
					created_offline_psw: true,
					bank: "Cars 5-6",
					message: "Fault 6",
				},
				{
					created_at: "2024-07-16T02:08:16.435731Z",
					updated_at: "2024-07-16T02:08:16.435731Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "test",
					status: "NEW",
					last_name: "pas",
					middle_name: "offline",
					offline_psw:
						"sZXe3S6DxSAhfBGTx5+gjpxfZSibHme+WGm+0/VLr+FdjXrAAHgYGEnOaMfnLe6h",
					email: "daniel.herrera+encript@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "00000000-0000-0000-0000-000000000000",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "0b438b2f-b3dc-4474-9d24-0d786f528f7c",
					created_offline_psw: true,
					bank: "Cars 5-6",
					message: "Fault 6",
				},
				{
					created_at: "2023-12-18T16:38:35.992509Z",
					updated_at: "2024-04-23T13:39:55.841118Z",
					pref_grid_id: "00000000-0000-0000-0000-000000000000",
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Herrrera",
					middle_name: "Diligent",
					offline_psw: "c30da9265ba0",
					email: "daniel.herrera+diligent@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "c3d6d774-10d3-431d-92ad-b411c83bd41d",
					role_id: "7ef3eb97-8f7d-4ab2-8f60-619f3033d0e2",
					org_id: "907730b5-7960-4dad-abee-124205ae438c",
					id: "0d449764-e18f-4b55-9131-6cd067cd641a",
					created_offline_psw: true,
					bank: "Cars 5-6",
					message: "Fault 6",
				},
			],
			bankId: "0cdc9370-5096-455e-9039-a8e799054653",
			bankName: "Cars 5-6",
		},
		{
			id: "054d7b45-61d7-455a-a382-6a57b9686528",
			message: "Fault 7",
			recipients: [
				{
					created_at: "2024-06-20T18:00:16.054373Z",
					updated_at: "2024-06-20T18:00:48.072208Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Action",
					middle_name: "Test",
					offline_psw: "xmoB2xDHoKE=",
					email: "daniel.herrera+actiontest@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
					created_offline_psw: true,
					bank: "Cars 5-6",
					message: "Fault 7",
				},
				{
					created_at: "2024-07-16T02:08:16.435731Z",
					updated_at: "2024-07-16T02:08:16.435731Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "test",
					status: "NEW",
					last_name: "pas",
					middle_name: "offline",
					offline_psw:
						"sZXe3S6DxSAhfBGTx5+gjpxfZSibHme+WGm+0/VLr+FdjXrAAHgYGEnOaMfnLe6h",
					email: "daniel.herrera+encript@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "00000000-0000-0000-0000-000000000000",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "0b438b2f-b3dc-4474-9d24-0d786f528f7c",
					created_offline_psw: true,
					bank: "Cars 5-6",
					message: "Fault 7",
				},
			],
			bankId: "0cdc9370-5096-455e-9039-a8e799054653",
			bankName: "Cars 5-6",
		},
		{
			id: "95723e61-73ae-44ce-9210-85abc28f7395",
			message: "Fault 8",
			recipients: [
				{
					created_at: "2024-06-20T18:00:16.054373Z",
					updated_at: "2024-06-20T18:00:48.072208Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Action",
					middle_name: "Test",
					offline_psw: "xmoB2xDHoKE=",
					email: "daniel.herrera+actiontest@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
					created_offline_psw: true,
					bank: "Cars 5-6",
					message: "Fault 8",
				},
				{
					created_at: "2024-07-16T02:08:16.435731Z",
					updated_at: "2024-07-16T02:08:16.435731Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "test",
					status: "NEW",
					last_name: "pas",
					middle_name: "offline",
					offline_psw:
						"sZXe3S6DxSAhfBGTx5+gjpxfZSibHme+WGm+0/VLr+FdjXrAAHgYGEnOaMfnLe6h",
					email: "daniel.herrera+encript@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "00000000-0000-0000-0000-000000000000",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "0b438b2f-b3dc-4474-9d24-0d786f528f7c",
					created_offline_psw: true,
					bank: "Cars 5-6",
					message: "Fault 8",
				},
			],
			bankId: "0cdc9370-5096-455e-9039-a8e799054653",
			bankName: "Cars 5-6",
		},
		{
			id: "e4a132a8-8ccc-4cb1-aabf-c0316b8bedf7",
			message: "Fault 9",
			recipients: [
				{
					created_at: "2024-06-20T18:00:16.054373Z",
					updated_at: "2024-06-20T18:00:48.072208Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Action",
					middle_name: "Test",
					offline_psw: "xmoB2xDHoKE=",
					email: "daniel.herrera+actiontest@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "81e574ce-2712-41b7-a74e-dc651b714d36",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "09143a99-e49d-4b7d-9038-3603e23bd1bd",
					created_offline_psw: true,
					bank: "Cars 5-6",
					message: "Fault 9",
				},
				{
					created_at: "2024-07-16T02:08:16.435731Z",
					updated_at: "2024-07-16T02:08:16.435731Z",
					pref_grid_id: null,
					accepted_terms: null,
					deleted_at: null,
					first_name: "test",
					status: "NEW",
					last_name: "pas",
					middle_name: "offline",
					offline_psw:
						"sZXe3S6DxSAhfBGTx5+gjpxfZSibHme+WGm+0/VLr+FdjXrAAHgYGEnOaMfnLe6h",
					email: "daniel.herrera+encript@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "00000000-0000-0000-0000-000000000000",
					role_id: "b254e133-5fa2-4f6a-a19a-9dfea9d7e6a2",
					org_id: "3a4d7837-c26b-4ce0-afc2-4aea42bb1724",
					id: "0b438b2f-b3dc-4474-9d24-0d786f528f7c",
					created_offline_psw: true,
					bank: "Cars 5-6",
					message: "Fault 9",
				},
				{
					created_at: "2023-12-18T16:38:35.992509Z",
					updated_at: "2024-04-23T13:39:55.841118Z",
					pref_grid_id: "00000000-0000-0000-0000-000000000000",
					accepted_terms: null,
					deleted_at: null,
					first_name: "Daniel",
					status: "ACTIVE",
					last_name: "Herrrera",
					middle_name: "Diligent",
					offline_psw: "c30da9265ba0",
					email: "daniel.herrera+diligent@hyperion-solutions.com",
					pref_theme: "",
					cognito_id: "c3d6d774-10d3-431d-92ad-b411c83bd41d",
					role_id: "7ef3eb97-8f7d-4ab2-8f60-619f3033d0e2",
					org_id: "907730b5-7960-4dad-abee-124205ae438c",
					id: "0d449764-e18f-4b55-9131-6cd067cd641a",
					created_offline_psw: true,
					bank: "Cars 5-6",
					message: "Fault 9",
				},
			],
			bankId: "0cdc9370-5096-455e-9039-a8e799054653",
			bankName: "Cars 5-6",
		},
	];

	// ************************************************ */
	// USE STATE VARIABLES **************************** */
	const [alerts, setAlerts] = useState<any[]>([]);
	const [total, setTotal] = useState(0);
	const [responseError, setResponseError] = useState<any>();

	// ************************************************ */
	// REDUX SLICE VARIABLES ************************** */
	const { theme } = useSelector((state: RootState) => state.home);

	// ************************************************ */
	// SERVICES AND API CALLS ************************* */
	const [triggerGetAlerts, { data, isLoading, isError, isFetching, error }] =
		useLazyGetAlertsQuery();

	// ************************************************ */
	// FUNCTIONS ************************************** */
	const handleClearData = () => {
		dispatch(notificationAPI.util.resetApiState());
	};

	const getData = async () => {
		const token = await GETJwtToken();
		handleClearData();
		triggerGetAlerts({
			token,
		});
	};

	const onClickCell = (record: any) => {
		if (record) {
			navigate({
				pathname: "/notification-management/notifications-users",
				search: `?idAlert=${record.id}&idBank=${record.bankId}`,
			});
		}
	};

	// ************************************************* */
	// USE EFFECT ************************************** */
	useEffect(() => {
		getData();
	}, [idBank]);

	useEffect(() => {
		if (data) {
			if (data && data.data) {
				if (idBank && idBank !== "") {
					const filteredAlerts: any = [];
					fakeData.map((item: any) => {
						if (item.bankId === idBank) {
							filteredAlerts.push(item);
						}
						return true;
					});
					setAlerts(filteredAlerts);
					setTotal(filteredAlerts.length);
				} else {
					setAlerts(fakeData);
					setTotal(fakeData.length);
				}
			}
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			setResponseError(error);
		}
	}, [error]);

	// ************************************************ */
	// COMPONENT ************************************** */
	return (
		<div>
			<div className='generalStyles__mlFix mt-1'>
				<Button
					className='buttonStyle__8'
					icon={
						<FontAwesomeIcon
							icon={faArrowsRotate}
							className='generalStyles__mrFix'
						/>
					}
					onClick={() => {
						getData();
					}}
				>
					{t("general.refreshTable")}
				</Button>
			</div>
			<Table
				locale={{
					emptyText: (
						<TableNoDataBox
							isError={isError}
							errorMessage={
								responseError &&
								responseError.data &&
								responseError.data.message
									? responseError.data.message
									: "Error"
							}
							noItemsFound='No data found'
						/>
					),
				}}
				loading={isLoading || isFetching}
				rowClassName={(record, index) => getTableRowClass(index, theme)}
				dataSource={alerts}
				size='small'
				className='mt-3'
				pagination={{
					showSizeChanger: true,
					pageSizeOptions: ["10", "20", "50", "100"],
				}}
				scroll={{ x: 900 }}
				footer={() => (
					<div className='generalStyles__flexEnd'>
						<Tag>{total} Records</Tag>
					</div>
				)}
			>
				{/** MESSAGE */}
				<Column
					onCell={(record: any) => ({
						onClick: () => {
							onClickCell(record);
						},
					})}
					title={t("general.message")}
					dataIndex='message'
					key='message'
					sorter={(a: any, b: any) => a.message.localeCompare(b.message)}
					render={(text: string) => <div className=''>{text}</div>}
				/>
				{/** BANK NAME */}
				<Column
					onCell={(record: any) => ({
						onClick: () => {
							onClickCell(record);
						},
					})}
					title={t("general.bank")}
					dataIndex='bankName'
					key='bankName'
					sorter={(a: any, b: any) => a.bankName.localeCompare(b.bankName)}
					render={(text: string) => <div className=''>{text}</div>}
				/>
				{/** USERS */}
				<Column
					onCell={(record: any) => ({
						onClick: () => {
							onClickCell(record);
						},
					})}
					title={t("general.users")}
					dataIndex='recipients'
					key='recipients'
					sorter={(a: any, b: any) => a.bankName.localeCompare(b.bankName)}
					render={(text, record) => (
						<div className=''>{record.recipients.length}</div>
					)}
				/>
			</Table>
		</div>
	);
}

export default NotificationAlertTable;
